import React, { memo, useEffect, useRef, useState } from 'react';
import { Button } from '@mafc/trolly';
import Image from '../common/Image';
import { useHistory } from 'react-router-dom';
import { LANGUAGES } from '../../constants';

const Certificates = ({ item, location, lang, language }) => {
  const history = useHistory();
  const pdfsView = useRef(null);
  const [showPdfs, setShowPdfs] = useState(false);

  useEffect(() => {
    if (location?.fromPdf) {
      setShowPdfs(true);
    }
  }, [location]);

  const handlePdfBackView = () => {
    if (!Boolean(pdfsView.current) || !showPdfs) {
      return;
    }

    pdfsView.current && pdfsView.current.scrollIntoView();
  };

  return (
    <div
      className="certificates"
      ref={(el) => {
        pdfsView.current = el;
        handlePdfBackView();
      }}
    >
      {item.certificatesURL.map((cert, index) => (
        <div key={index} className="certificateBlock">
          <Image className="certificate" src={cert.image} alt="cerificate" loading="lazy" />
          <Button
            variant="outlined"
            borderRadius={'8px'}
            borderWidth={'2px'}
            fontFamily={"Montserrat,'Noto Kufi Arabic', sans-serif"}
            fontWeight={'600'}
            padding={'0 12px'}
            paddingRight={'12px'}
            paddingLeft={'12px'}
            height={'34px'}
            lineHeight={'32px'}
            className="button"
            value={cert.certificate}
            onClick={() => {
              const url = cert.certificate;
              history.push({
                pathname: '/show-pdf',
                state: {
                  prevPath: location.pathname,
                  pdfUrl: url,
                  lang: lang,
                  lotno: location.state?.lotno || undefined,
                  country: location?.state?.country || undefined
                }
              });
            }}
            data-testid="view-certificate"
          >
            {language === LANGUAGES.ARABIC.langCode ? 'عرض الشهادة' : 'view certificate'}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default memo(Certificates);
