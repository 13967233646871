import React, { memo, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ERROR_CODE, FREQUENT_WORDS, LANGUAGES, VALIDATIONS } from '../../constants';
import { subDays } from '../../utils';

const DatePicker = ({
  selectedDate,
  handleDateChange,
  productType,
  apiErrorCode,
  language,
  dateErrorMsg
}) => {
  const minDate = useMemo(() => {
    const minDays = VALIDATIONS[productType]?.minDays || 10;
    return new Date(subDays(minDays));
  }, [productType]);

  return (
    <>
      <div className="inputContainer">
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => handleDateChange(date)}
          dateFormat="dd/MM/yyyy"
          minDate={minDate}
          maxDate={new Date()}
          placeholderText="Select date"
          className={
            apiErrorCode === ERROR_CODE.INCORRECT_DATA
              ? 'date-picker-input red-date-picker'
              : 'date-picker-input'
          }
        />
      </div>
      {dateErrorMsg?.length > 0 && VALIDATIONS[productType]?.date && (
        <p className="errorStart">
          {language === LANGUAGES.ARABIC.langCode
            ? FREQUENT_WORDS.ARABIC.DATE_ERROR
            : FREQUENT_WORDS.ENGLISH.DATE_ERROR}
        </p>
      )}
      {apiErrorCode === ERROR_CODE.INCORRECT_DATA && (
        <p className="incorrect-input">
          {language === LANGUAGES.ARABIC.langCode
            ? FREQUENT_WORDS.ARABIC.INCORRECT_DATE
            : FREQUENT_WORDS.ENGLISH.INCORRECT_DATE}
        </p>
      )}
    </>
  );
};

export default memo(DatePicker);
