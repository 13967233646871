import React, { memo, useMemo } from 'react';
import Image from '../common/Image';
import mainImage1 from '../../images/mainImage1.png';
import honeyImage from '../../images/lotImage.jpg';
import fishImage from '../../images/fishPriceTagImage.jpg';
import organicChickenImage from '../../images/organicChickenImage.png';
import {
  PRODUCT_TYPES,
  EGGS_LANDING_IMAGE,
  GRAPES_LANDING_IMAGE,
  ORGANIC_CARROTS_LANDING_IMAGE,
  ORGANIC_CUCUMBER_LANDING_IMAGE,
  UHT_MILK_LANDING_IMAGE,
  SALAD_LEAVES_LANDING_IMAGE,
  IFFCO_EGGS_LANDING_IMAGE,
  INDIA_GRAPES_LANDING_IMAGE,
  RTE_AVOCADO_LANDING_IMAGE,
  EGYPT_GRAPES_LANDING_IMAGE
} from '../../constants';

// const GRAPES = { RED: '6294017506103', WHITE: '6294017506110', BLACK: '6294017506127'};

const LandingImage = ({ pid, productType }) => {
  const landingImage = useMemo(() => {
    let image = '';

    switch (productType) {
      case PRODUCT_TYPES.HONEY:
        image = honeyImage;
        break;
      case PRODUCT_TYPES.FISH:
        image = fishImage;
        break;
      case PRODUCT_TYPES.ORGANICCHICKEN:
        image = organicChickenImage;
        break;
      case PRODUCT_TYPES.EGGS:
        image = EGGS_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.GRAPES:
        image = GRAPES_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.ORGANICCARROTS:
        image = ORGANIC_CARROTS_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.ORGANICCUCUMBER:
        image = ORGANIC_CUCUMBER_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.MILK:
        image = UHT_MILK_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.SALADLEAVES:
        image = SALAD_LEAVES_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.IFFCOEGGS:
        image = IFFCO_EGGS_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.INDIAGRAPES:
        image = INDIA_GRAPES_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.RTEAVOCADO:
        image = RTE_AVOCADO_LANDING_IMAGE;
        break;
      case PRODUCT_TYPES.EGYPT_GRAPES:
        image = EGYPT_GRAPES_LANDING_IMAGE.replace('##pid##', pid);
        break;
      default:
        image = mainImage1;
    }

    return image;
  }, [productType, pid]);

  return (
    <div className="barcodeImageContainer">
      <Image
        className="productImageLandingPage"
        src={landingImage}
        alt="product"
        loading="lazy"
        dataTestId="landingImage"
      />
    </div>
  );
};

export default memo(LandingImage);
