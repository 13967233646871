import React, { useMemo } from 'react';
import { Button, Select } from '@mafc/trolly';
import downarrow from '../../images/arrow.svg';
import { toast } from 'react-toastify';
import {
  CHICKEN_TYPES,
  COUNTRY_CODES,
  FREQUENT_WORDS,
  ITALY_GRAPES_2023,
  LANGUAGES,
  PRODUCT_TYPES,
  VALIDATIONS
} from '../../constants';
import DatePicker from '../batchcode/DatePicker';
import LandingImage from '../batchcode/LandingImage';
import InputLabel from '../batchcode/InputLabel';
import InputField from '../batchcode/InputField';

const toastId = 'toastId';

const Filter = ({
  language,
  productCode,
  productType,
  checkStoreLocation,
  apiErrorCode,
  setApiErrorCode,
  error,
  setError,
  batchCode,
  setBatchCode,
  errorMsg,
  lotNo,
  setLotNo,
  date,
  setDate,
  apiErrorMsg,
  dateErrorMsg,
  setDateErrorMsg,
  storesList,
  productList,
  selectedCountry,
  setSelectedCountry,
  setCurrentStore,
  setSelectedProductType,
  selectedProductType,
  showElementSearch,
  setShowElementSearch
}) => {
  const toggleCheckBox = () => {
    setShowElementSearch(!showElementSearch);
  };

  const italyGrapes2023 = useMemo(() => ITALY_GRAPES_2023[language], [language]);
  const chickenTypes = useMemo(() => {
    let lang = CHICKEN_TYPES.en;
    if (language) {
      lang = CHICKEN_TYPES[language];
    }
    return lang;
  }, [language]);
  const countryCodes = useMemo(() => COUNTRY_CODES[language], [language]);

  const handleChange = (e) => {
    if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
    }
    e.preventDefault();
    const value = e.target.value;
    setBatchCode(value);
    setApiErrorCode('');
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const onLotNumberChange = (e) => {
    toast.dismiss();
    const value = e.target.value;
    setLotNo(value);
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
    setApiErrorCode(false);
  };

  const handleDateChange = (data) => {
    if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
    }
    setDateErrorMsg('');
    setApiErrorCode('');
    setDate(data);
  };

  return (
    <>
      <Button
        variant="outlined"
        alignSelf="center"
        borderRadius={'8px'}
        fontSize="16px"
        fontWeight="bold"
        lineHeight="24px"
        borderWidth={'2px'}
        width="310px"
        paddingLeft="15px"
        paddingRight="15px"
        paddingTop="9px"
        paddingBottom="9px"
        textAlign="center"
        marginLeft={language === LANGUAGES.ARABIC.langCode ? 'auto' : '25px'}
        marginRight={language === LANGUAGES.ARABIC.langCode ? '25px' : 'auto'}
        marginTop="20px"
        letterSpacing="1"
        overflowWrap="inherit"
        onClick={toggleCheckBox}
        data-testid="expand-button"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ color: '#005cad' }}>
            {language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.DROPDOWN_TEXT
              : FREQUENT_WORDS.ENGLISH.DROPDOWN_TEXT}
          </div>
          <img
            className="arrowdown"
            src={downarrow}
            alt="carrefor"
            loading="lazy"
            width="25"
            height="30"
          />
        </div>
      </Button>
      {showElementSearch && (
        <div
          className={language === LANGUAGES.ARABIC.langCode ? language : LANGUAGES.ENGLISH.langCode}
        >
          {/* <div className="closeImgContainer" >    <img src={closeImg} alt="close" className="closeImage" /></div>  */}
          <div className="batchcodeContainer">
            <LandingImage pid={productCode} productType={productType} />
            <InputLabel />
            {VALIDATIONS[productType]?.input && (
              <InputField
                language={language}
                apiErrorCode={apiErrorCode}
                handleChange={handleChange}
                pid={productCode}
                productType={productType}
                batchCode={batchCode}
                error={error}
                errorMsg={errorMsg}
                onLotNumberChange={onLotNumberChange}
                lotNo={lotNo}
              />
            )}
            {productType === PRODUCT_TYPES.CHICKEN && (
              <div className="selectContainer inputContainer">
                <Select
                  options={chickenTypes}
                  onChange={(val) => {
                    toast.dismiss();
                    setApiErrorCode('');
                    setSelectedProductType(val?.id);
                  }}
                  data-testid="chicken-codes"
                />
              </div>
            )}
            {productType === PRODUCT_TYPES.KARSTENGRAPES && productCode === '6294017503317' && (
              <div className="selectContainer inputContainer">
                <Select
                  options={italyGrapes2023}
                  onChange={(val) => {
                    toast.dismiss();
                    setApiErrorCode('');
                    // setProductCode(val);
                  }}
                  value={italyGrapes2023?.filter((ig) => ig?.id === productCode?.id)[0]?.label}
                  data-testid="italy-grapes-codes"
                />
              </div>
            )}
            {productType === PRODUCT_TYPES.MEDI_GARDEN_GRAPES &&
              productCode === '6294017503317' && (
                <div className="selectContainer inputContainer">
                  <Select
                    options={italyGrapes2023}
                    onChange={(val) => {
                      toast.dismiss();
                      setApiErrorCode('');
                      // setProductCode(val);
                    }}
                    value={italyGrapes2023?.filter((ig) => ig?.id === productCode?.id)[0]?.label}
                    data-testid="medi-garden-grapes"
                  />
                </div>
              )}
            {productType === PRODUCT_TYPES.HONEY && (
              <div className="selectContainer inputContainer">
                <Select
                  options={countryCodes}
                  onChange={(e, newValue) => {
                    toast.dismiss();
                    setApiErrorCode('');
                    setSelectedCountry(e);
                  }}
                  value={selectedCountry.label}
                  data-testid="country-codes"
                />
              </div>
            )}
            {productType === PRODUCT_TYPES.ORGANICCHICKEN && (
              <>
                <div className="selectContainer inputContainer">
                  <Select
                    options={productList}
                    onChange={(e, newValue) => {
                      toast.dismiss();
                      setApiErrorCode('');
                      setSelectedProductType(e.value);
                    }}
                    data-testid="selectInputOC"
                  />
                </div>
              </>
            )}
            {productType === PRODUCT_TYPES.HYDROPONICS && (
              <>
                <div className="selectContainer inputContainer">
                  <Select
                    options={storesList}
                    data-testid="selectInput"
                    // label={
                    //   language === LANGUAGES.ARABIC.langCode
                    //     ? FREQUENT_WORDS.ARABIC.SELECT_STORE
                    //     : FREQUENT_WORDS.ENGLISH.SELECT_STORE
                    // }
                    onChange={(e, newValue) => {
                      toast.dismiss();
                      setApiErrorCode('');
                      setCurrentStore(e.value);
                    }}
                  />
                </div>
              </>
            )}
            {VALIDATIONS[productType]?.date && (
              <DatePicker
                selectedDate={date}
                handleDateChange={handleDateChange}
                productType={productType}
                apiErrorCode={apiErrorCode}
                language={language}
                dateErrorMsg={dateErrorMsg}
              />
            )}
          </div>
          <div className="centerBtn">
            <Button
              variant="primary"
              width="296px"
              height="48px"
              onClick={checkStoreLocation}
              paddingLeft="10px"
              borderRadius="4px"
              data-testid="countinueBtn"
            >
              {language === LANGUAGES.ARABIC.langCode
                ? FREQUENT_WORDS.ARABIC.CONTINUE
                : FREQUENT_WORDS.ENGLISH.CONTINUE}
            </Button>
          </div>
          {error && !VALIDATIONS[productType]?.productField && !VALIDATIONS[productType]?.lotNo && (
            <p className="errorCenter">
              {errorMsg === 'required' &&
                (language === LANGUAGES.ARABIC.langCode
                  ? FREQUENT_WORDS.ARABIC.ITEM_NUMBER_ERROR
                  : FREQUENT_WORDS.ENGLISH.ITEM_NUMBER_ERROR)}
              {errorMsg === 'minLength' &&
                (language === LANGUAGES.ARABIC.langCode
                  ? FREQUENT_WORDS.ARABIC.ITEM_NUMBER_LENGTH_ERROR +
                    ' ' +
                    VALIDATIONS[productType]?.maxLength +
                    ' ' +
                    FREQUENT_WORDS.ARABIC.DIGITS
                  : FREQUENT_WORDS.ENGLISH.ITEM_NUMBER_LENGTH_ERROR +
                    ' ' +
                    VALIDATIONS[productType]?.maxLength +
                    ' ' +
                    FREQUENT_WORDS.ENGLISH.DIGITS)}
            </p>
          )}
          {apiErrorMsg && (
            <p className="errorCenter" data-testid="apiErrorMsg">
              {language === LANGUAGES.ARABIC.langCode
                ? FREQUENT_WORDS.ARABIC.NO_INFO
                : FREQUENT_WORDS.ENGLISH.NO_INFO}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default Filter;
