import React, { memo } from 'react';
import Image from '../common/Image';

const JourneyEndComponent = ({ logo }) => (
  <div>
    <div className="productImageContainerBottom">
      <Image className="carrefourLogo" src={logo} alt="carrefor" loading="lazy" />
    </div>
  </div>
);

export default memo(JourneyEndComponent);
