import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Footer, Header } from '@mafc/common';
import { useHistory, useParams } from 'react-router-dom';
import {
  API_SOURCE,
  API_URLS,
  CHICKEN_TYPES,
  COUNTRY_CODES,
  DEAFULT_STORE,
  ERROR_CODE,
  FREQUENT_WORDS,
  HYDROPONICS_STORES,
  ITALY_GRAPES_2023,
  LANGUAGES,
  PRODUCT_TYPES,
  REGEX_USED,
  VALIDATIONS
} from '../../constants';
import Commonselectandshare from '../../components/common/commonselectandshare';
import { Button } from '@mafc/trolly';
import Image from '../../components/common/Image';
import JourneyEndComponent from '../../components/common/journeyEndComponent';
import Components from '../../components/product-details/Components';
import Videos from '../../components/product-details/Videos';
import ProductTitleAndDesc from '../../components/product-details/ProductTitleAndDesc';
import logo from '../../images/carrefour.svg';
import salesman from '../../images/avatar.png';
import shareImg from '../../images/share.png';
import downarrow from '../../images/arrow.svg';
import UAEFLAG from '../../images/UAE-flag.png';
import {
  convertDateToSlashedFormat,
  getServerResponse,
  NearestStore,
  status,
  zeroPad
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import useProduct from '../../hooks/useProduct';
import { setApiData } from '../../actions/product';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loaderrr
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/productDetails.css';
import '../../styles/rtl.css';
import '../../styles/animate.css';
import Filter from '../../components/filter';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../components/loader';

const toastId = 'toastId';

const getPopupObject = (language) => {
  return {
    toastId,
    position: 'top-center',
    hideProgressBar: true,
    theme: 'colored',
    autoClose: false,
    rtl: language === LANGUAGES.ARABIC.langCode,
    closeButton: (
      <span className="popup-close-label" onClick={() => toast.dismiss(toastId)}>
        {language === LANGUAGES.ARABIC.langCode
          ? FREQUENT_WORDS.ARABIC.CLOSE
          : FREQUENT_WORDS.ENGLISH.CLOSE}
      </span>
    ),
    bodyClassName: 'popup-msg-body',
    style: { marginTop: 50, padding: 10 },
    closeOnClick: false,
    draggable: false
  };
};

const LandingPage = (props) => {
  const refParallax = useRef();
  const [error, setError] = useState(false);
  const [productCode, setProductCode] = useState('');
  const [apiErrorCode, setApiErrorCode] = useState('');
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { getProductNameForApi } = useProduct();
  const [lotNo, setLotNo] = useState('');
  const [batchCode, setBatchCode] = useState('');
  const [date, setDate] = useState('');
  const [currentStore, setCurrentStore] = useState('');
  const [nearestStoreKey, setNearestStoreKey] = useState('');
  const [productList, setProductList] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState(false);
  const [storesList, setStoresList] = useState([]);
  const [productListApiData, setProductListApiData] = useState([]);
  let { pid, lang, productTypeSlug, storecode = 'maf' } = useParams();
  const [selectedProductType, setSelectedProductType] = useState(pid || '');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [productType, setProductType] = useState('');
  const { apiData } = useSelector(({ productReducers }) => productReducers);
  const [showElementSearch, setShowElementSearch] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const italyGrapes2023 = ITALY_GRAPES_2023[lang];

  const { componentList = [] } = apiData;

  const countryCodes = COUNTRY_CODES[lang];

  useEffect(() => {
    if (productType === PRODUCT_TYPES.KARSTENGRAPES && pid === '6294017503317') {
      setProductCode(italyGrapes2023[2]);
    } else {
      if (!pid && productType === PRODUCT_TYPES.CHICKEN) {
        if (lang) {
          setSelectedProductType(CHICKEN_TYPES[lang][0].id);
        } else {
          setSelectedProductType(CHICKEN_TYPES.en[0].id);
        }
      }
      setProductCode(pid);
    }
  }, [italyGrapes2023, lang, pid, productType]);

  useEffect(() => {
    if (productTypeSlug) {
      setProductType(productTypeSlug);
    } else {
      setProductType('chicken');
    }
  }, [productTypeSlug]);

  useEffect(() => {
    if (!productType) return;
    window.dataLayerObj = {
      event: 'custom_event',
      event_category: 'user_engagement',
      event_action: 'ift_landing_page',
      market: 'mafuae',
      screen_type: 'ift_landing_page',
      language: lang,
      page_url: window.location.href
    };
    let trackProductData = ({
      userLoc = null,
      storeId = null,
      distanceInKm = null,
      lat = null,
      long = null
    } = {}) => {
      window.dataLayerObj.event_label = `productId=${productCode}_productType=${productType}_userLoc=${userLoc}_storeId=${storeId}_lat=${lat}_long=${long}_distanceInKm=${distanceInKm}_end`;
      window.dataLayer.push(window.dataLayerObj);
    };
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Callback function for asynchronous call to HTML5 geolocation
        (position) => {
          let nearestStoreObject = NearestStore(
            position.coords.latitude,
            position.coords.longitude
          );
          setNearestStoreKey(zeroPad(nearestStoreObject?.id));
          trackProductData({
            userLoc: nearestStoreObject.name,
            storeId: nearestStoreObject.id,
            distanceInKm: nearestStoreObject.distanceInKm,
            lat: position.coords.latitude,
            long: position.coords.longitude
          });
        },
        (error) => {
          trackProductData();
        }
      );
    } else {
      trackProductData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateProductCode = (code) => {
    const format = [PRODUCT_TYPES.CHICKEN, PRODUCT_TYPES.RTEAVOCADO].includes(productType)
      ? REGEX_USED.NUMBER_FORMAT
      : REGEX_USED.ALPHANUMRICFORMAT;
    if (code.length === 0 && VALIDATIONS[productType]?.required) {
      if (![PRODUCT_TYPES.RTEAVOCADO].includes(productType)) setErrorMsg(`required`);
      setError(true);
      return false;
    }

    if (
      !format.test(code) ||
      (productType === PRODUCT_TYPES.CHICKEN &&
        code?.length &&
        code?.length < VALIDATIONS[productType]?.maxLength)
    ) {
      setErrorMsg('minLength');
      setError(true);
      return false;
    }
    // Todo: Add validation for Lot No
    // if (!format.test(code) || (name !== productString && code?.length && code?.length < VALIDATIONS[productType]?.lotMaxLength)) {
    //   setErrorMsg('minLength');
    //   setError(true);
    //   return false;
    // }
    setError(false);
    return true;
  };
  const validateDate = () => {
    let dateValid = true;
    if (!date && VALIDATIONS[productType]?.date) {
      setDateErrorMsg(`Production date is required`);
      dateValid = false;
    } else {
      setDateErrorMsg('');
    }

    return dateValid;
  };

  const checkValidations = () => {
    let tempErrors = {
      product: false,
      date: false
    };

    tempErrors.date = validateDate();

    if (
      ![
        PRODUCT_TYPES.ORGANICCHICKEN,
        PRODUCT_TYPES.FISH,
        PRODUCT_TYPES.FRESHMILK,
        PRODUCT_TYPES.LABAN,
        PRODUCT_TYPES.SALMON,
        PRODUCT_TYPES.AGRICO_MUSHROOM,
        PRODUCT_TYPES.HAYATNA_MILK
      ].includes(productType)
    ) {
      if (![PRODUCT_TYPES.CHICKEN, PRODUCT_TYPES.HYDROPONICS].includes(productType)) {
        tempErrors.product = validateProductCode(lotNo);
      } else {
        tempErrors.product = validateProductCode(productCode || selectedProductType);
      }
    } else {
      tempErrors.product = true;
    }

    const valid = Object.values(tempErrors).every((value) => value);
    if (!valid) {
      setApiErrorMsg(false);
      setLoading(false);
    }

    return valid;
  };

  const checkStoreLocation = (isButtonClicked = false) => {
    if (isButtonClicked) {
      if (!checkValidations() || apiErrorCode) {
        return false;
      }
    }
    if (!pid && !selectedProductType) {
      setShowElementSearch(true);
      return false;
    }
    setLoading(true);
    dispatch(setApiData({}));
    if (productType === PRODUCT_TYPES.HYDROPONICS) {
      fetchData();
      return;
    }
    if (nearestStoreKey?.length > 0) {
      fetchData(nearestStoreKey);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Callback function for asynchronous call to HTML5 geolocation
        (position) => {
          let nearestStoreObject = NearestStore(
            position.coords.latitude,
            position.coords.longitude
          );
          fetchData(zeroPad(nearestStoreObject?.id));
        },
        (error) => {
          fetchData();
        }
      );
    } else {
      fetchData();
    }
  };

  let batchcodewithoutslashes = selectedProductType.replace(REGEX_USED.REMOVE_SLASH, '');

  const isPilot = useMemo(() => {
    let isPilot = false;
    if (VALIDATIONS[productType]?.lotNo && !lotNo) {
      isPilot = true;
    }
    if (VALIDATIONS[productType]?.packingCode && !lotNo) {
      isPilot = true;
    }
    if (VALIDATIONS[productType]?.date && !date) {
      isPilot = true;
    }
    return isPilot;
  }, [date, lotNo, productType]);

  const fetchData = (storeLocation = '') => {
    const storeNew = currentStore?.length > 0 ? currentStore : storecode;
    const storecodeVal = storeNew || DEAFULT_STORE;
    const productId = [PRODUCT_TYPES.ORGANICCHICKEN, PRODUCT_TYPES.CHICKEN].includes(productType)
      ? selectedProductType || pid
      : (productType === PRODUCT_TYPES.KARSTENGRAPES && pid === '6294017503317'
          ? productCode.id
          : productCode) || batchcodewithoutslashes;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('lang', lang);
    myHeaders.append('src', API_SOURCE);
    const lotPrefix = VALIDATIONS[productType]?.lotPrefix
      ? VALIDATIONS[productType]?.lotPrefix
      : '';
    const lotPostfix = VALIDATIONS[productType]?.lotPostfix
      ? VALIDATIONS[productType]?.lotPostfix
      : '';

    var raw = JSON.stringify({
      productCode: productId,
      date: [
        PRODUCT_TYPES.CHICKEN,
        PRODUCT_TYPES.GRAPES,
        PRODUCT_TYPES.ORGANICCARROTS,
        PRODUCT_TYPES.HONEY,
        PRODUCT_TYPES.ORGANICCUCUMBER,
        PRODUCT_TYPES.INDIAGRAPES,
        PRODUCT_TYPES.MEDI_GARDEN_GRAPES,
        PRODUCT_TYPES.SALADLEAVES,
        PRODUCT_TYPES.KARSTENGRAPES,
        PRODUCT_TYPES.ELITEPOTATO,
        PRODUCT_TYPES.ELITEPOTATO_PROMO,
        PRODUCT_TYPES.MEDI_GARDEN_GRAPES,
        PRODUCT_TYPES.MUSHROOM,
        PRODUCT_TYPES.STRAWBERRY,
        PRODUCT_TYPES.BLUEBERRY,
        PRODUCT_TYPES.RTEAVOCADO,
        PRODUCT_TYPES.EGYPT_GRAPES,
        PRODUCT_TYPES.INDIA_MULHOUSE_GRAPES
      ].includes(productType)
        ? ''
        : date
        ? convertDateToSlashedFormat(date)
        : '',
      type: getProductNameForApi(productType, pid),
      storeKey: storeLocation.length > 0 ? storeLocation : storecodeVal,
      lotno: [
        PRODUCT_TYPES.COFFEE,
        PRODUCT_TYPES.CHICKEN,
        PRODUCT_TYPES.RTEAVOCADO,
        PRODUCT_TYPES.SEABREAM_FISH
      ].includes(productType)
        ? ''
        : lotPrefix + lotNo + lotPostfix,
      location:
        productType === PRODUCT_TYPES.HONEY && selectedCountry ? selectedCountry?.id : undefined,
      packingCode: [PRODUCT_TYPES.RTEAVOCADO].includes(productType) ? lotNo : undefined,
      pilot: isPilot ? true : undefined
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setLoading(true);
    fetch(API_URLS.product, requestOptions)
      .then(getServerResponse)
      .then((response) => status(response) && response.json())
      .then((result) => {
        let parsedData = result.data;
        dispatch(setApiData(parsedData));
        setLoading(false);
        setShowElementSearch(false);
      })
      .catch((err) => {
        setLoading(false);
        // err?.errorCode === ERROR_CODE.INCORRECT_DATA this condition will be removed once we implement 2002 error code
        if (!isPilot) {
          if (err?.errorCode && err?.errorCode === ERROR_CODE.INCORRECT_DATA) {
            setApiErrorCode(err?.errorCode);
            if (err?.errorCode === ERROR_CODE.INCORRECT_DATA) {
              toast.error(
                lang === LANGUAGES.ARABIC.langCode
                  ? FREQUENT_WORDS.ARABIC.INCORRECT_INFO_MSG
                  : FREQUENT_WORDS.ENGLISH.INCORRECT_INFO_MSG,
                getPopupObject(lang)
              );
            }
          } else {
            setApiErrorMsg(true);
          }
          setShowElementSearch(true);
        } else {
          history.push('/error-page');
        }
      });
  };

  useEffect(() => {
    if (productType === PRODUCT_TYPES.ORGANICCHICKEN) {
      if (productList?.length === 0) {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Access-Control-Allow-Origin', '*');
        myHeaders.append('src', API_SOURCE);
        // myHeaders.append('type', productType);
        var raw = JSON.stringify({
          type: PRODUCT_TYPES.ORGANICCHICKEN
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        setLoading(true);
        fetch(API_URLS.list, requestOptions)
          .then(status)
          .then((response) => response.json())
          .then((result) => {
            let parsedData = result?.data?.list;
            setProductListApiData(parsedData);
            let transformedData = parsedData.map((item) => {
              return { id: item.id, label: item[lang], value: item.id + '' };
            });
            if (transformedData.length > 0) {
              setSelectedProductType(transformedData[0].value);
            }
            setProductList(transformedData);
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
          });
      } else {
        let transformedData = productListApiData.map((item) => {
          return { id: item.id, label: item[lang], value: item.id + '' };
        });
        if (transformedData.length > 0) {
          setSelectedProductType(transformedData[0].value);
        }
        setProductList(transformedData);
      }
    }
    if (productType === PRODUCT_TYPES.HYDROPONICS) {
      let storesData = HYDROPONICS_STORES.map((item) => {
        return { id: item.id, label: item[lang], value: item.id + '' };
      });
      setCurrentStore(storesData[0].value);
      setStoresList(storesData);
    }
    if (productType === PRODUCT_TYPES.HONEY) {
      setSelectedCountry(countryCodes[0]);
    }
    if (productType) {
      checkStoreLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType, lang]);

  const handleLanguageChange = (e) => {
    e.preventDefault();
    const selectedLang = e.target.value;
    history.replace({
      pathname: `/${selectedLang}/${productType}/pid/${
        productCode || selectedProductType
      }/storecode/${storecode}`
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      ref={refParallax}
      className={`overflowHidden ${
        lang === LANGUAGES.ARABIC.langCode ? lang : LANGUAGES.ENGLISH.langCode
      }`}
    >
      <Header
        cloneHeight
        // isDeliveryArea
        isLanguageChangeEnabled={true}
        // isLanguageChange={AppConfig.getConfig().features?.languageChange}
        isDeliveryArea={false}
        isMenuNav={false}
        onLanguageChange={() => window.location.reload(true)}
        isSearchBox={false}
        isMiniCart={false}
      />
      <div className="container">
        <Commonselectandshare
          UAEFLAG={UAEFLAG}
          language={lang}
          handleLanguageChange={handleLanguageChange}
          shareImg={shareImg}
          productCode={productCode}
          // productcode={productcode}
          lotno={lotNo}
          date={convertDateToSlashedFormat(date)}
          showShare={true}
          downarrow={downarrow}
          storecode={storecode}
          productType={productType}
        />
        <div className="deviderLine"></div>
        <div
          className={
            productType === PRODUCT_TYPES.HYDROPONICS
              ? 'productImageContainerHydo'
              : 'productImageContainer'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Image className="carrefourLogo" src={logo} alt="carrefor" loading="lazy" />
            {![PRODUCT_TYPES.COFFEE, PRODUCT_TYPES.SEABREAM_FISH].includes(productType) && (
              <Filter
                language={lang}
                productCode={productCode}
                productType={productType}
                checkStoreLocation={checkStoreLocation}
                apiErrorCode={apiErrorCode}
                setApiErrorCode={setApiErrorCode}
                error={error}
                setError={setError}
                batchCode={batchCode}
                setBatchCode={setBatchCode}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                lotNo={lotNo}
                setLotNo={setLotNo}
                date={date}
                setDate={setDate}
                apiErrorMsg={apiErrorMsg}
                dateErrorMsg={dateErrorMsg}
                setDateErrorMsg={setDateErrorMsg}
                storesList={storesList}
                productList={productList}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                setCurrentStore={setCurrentStore}
                setSelectedProductType={setSelectedProductType}
                selectedProductType={selectedProductType}
                showElementSearch={showElementSearch}
                setShowElementSearch={setShowElementSearch}
                setProductCode={setProductCode}
              />
            )}
          </div>
        </div>
        {Object.keys(apiData)?.length > 0 && (
          <>
            <ProductTitleAndDesc />
            <Videos productType={productType} />
            <Components
              lang={lang}
              language={lang}
              refParallax={refParallax}
              location={props.location}
              productType={productType}
              templeteTypes={apiData}
              productcode={productCode}
              componentList={componentList}
              isPilot={isPilot}
            />
            <JourneyEndComponent
              // item={journeyEndComponentObj}
              logo={logo}
              // language={langÇuage}
            />

            <div className="deviderLine"></div>
            {!isLoading &&
              !apiErrorCode &&
              !apiErrorMsg &&
              ![PRODUCT_TYPES.COFFEE, PRODUCT_TYPES.SEABREAM_FISH].includes(productType) && (
                <Filter
                  language={lang}
                  productCode={productCode}
                  productType={productType}
                  checkStoreLocation={checkStoreLocation}
                  apiErrorCode={apiErrorCode}
                  setApiErrorCode={setApiErrorCode}
                  error={error}
                  setError={setError}
                  batchCode={batchCode}
                  setBatchCode={setBatchCode}
                  errorMsg={errorMsg}
                  setErrorMsg={setErrorMsg}
                  lotNo={lotNo}
                  setLotNo={setLotNo}
                  date={date}
                  setDate={setDate}
                  apiErrorMsg={apiErrorMsg}
                  dateErrorMsg={dateErrorMsg}
                  setDateErrorMsg={setDateErrorMsg}
                  storesList={storesList}
                  productList={productList}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  setCurrentStore={setCurrentStore}
                  setSelectedProductType={setSelectedProductType}
                  selectedProductType={selectedProductType}
                  showElementSearch={showElementSearch}
                  setShowElementSearch={setShowElementSearch}
                  setProductCode={setProductCode}
                />
              )}
            <div className="addMoreCart">
              <p className="salesHeader">
                {' '}
                {lang === LANGUAGES.ARABIC.langCode
                  ? FREQUENT_WORDS.ARABIC.NEXT_TODO
                  : FREQUENT_WORDS.ENGLISH.NEXT_TODO}
              </p>
              <Button
                variant="outlined"
                borderRadius={'8px'}
                fontSize="16px"
                fontWeight="bold"
                lineHeight="24px"
                borderWidth={'2px'}
                width="270px"
                paddingLeft="15px"
                paddingRight="15px"
                paddingTop="9px"
                paddingBottom="9px"
                textAlign="center"
                marginLeft="auto"
                marginRight="auto"
                letterSpacing="1"
                overflowWrap="inherit"
                onClick={() =>
                  history.push({
                    pathname: '/camera',
                    state: {
                      lang
                    }
                  })
                }
                data-testid="scan-button"
              >
                {lang === LANGUAGES.ARABIC.langCode
                  ? FREQUENT_WORDS.ARABIC.SCAN
                  : FREQUENT_WORDS.ENGLISH.SCAN}
              </Button>
              {/* </div> */}
              <img
                src={salesman}
                alt="salesman"
                style={{
                  height: '251px',
                  width: '262px',
                  marginTop: '24px',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                loading="lazy"
              />
            </div>
          </>
        )}
        <ToastContainer />
      </div>
      {Object.keys(apiData)?.length > 0 && <Footer />}
    </div>
  );
};

export default LandingPage;
