import { useLocation } from 'react-router-dom';
import { LANGUAGES, mappedMonths } from '../constants';

import { latlanginfo } from '../latlanginfo';
// A custom hook that builds on useLocation to parse
// the query string for you.
const getConversionLanguageDigits = (langConvertCode) => {
  switch (langConvertCode) {
    case LANGUAGES.ARABIC.langCode:
    default:
      return '٠١٢٣٤٥٦٧٨٩';
  }
};
const EntoSomeLanguage = function (stringToReplace, langConvertCode) {
  const conversionLanguageDigits = getConversionLanguageDigits(langConvertCode);
  return stringToReplace.replace(/\d/g, (d) => conversionLanguageDigits[d]);
};

const filterDateByDateFields = (dateDetails, dateFields) => {
  let resultDateString = '';
  dateFields.forEach((field, index) => {
    const fieldVal = dateDetails[field];
    resultDateString += index < dateFields.length - 1 ? fieldVal + ' ' : fieldVal;
  });
  return resultDateString;
};
const getDateFieldsByDateFormat = (dateFormat) => {
  switch (dateFormat) {
    case 'yyyy':
      return ['year'];
    case 'yyyy/MMM':
    case 'MMM-yyyy':
      return ['month', 'year'];
    case 'dd-MMM-yyyy':
    default:
      return ['date', 'month', 'year'];
  }
};
export const getDateDetails = (date, languageParam) => {
  if (!date) return null;
  const dateDetails = {
    year: date.getFullYear(),
    month: mappedMonths(languageParam)[date.getMonth()],
    date: date.getDate()
  };
  if (languageParam) {
    Object.keys(dateDetails).forEach((key) => {
      dateDetails[key] = isNaN(dateDetails[key])
        ? dateDetails[key].toLocaleString(languageParam)
        : EntoSomeLanguage(dateDetails[key]?.toString(), languageParam);
    });
  }
  return dateDetails;
};
export const fromAndToDateFormatter = (fromDate, toDate, languageParam, format) => {
  if (!fromDate && !toDate) return null;
  else if (!fromDate || !toDate) return fromDate ? fromDate : toDate;
  const fromDateDetails = getDateDetails(fromDate, languageParam),
    toDateDetails = getDateDetails(toDate, languageParam);
  const dateFields = getDateFieldsByDateFormat(format);
  let unmatchedFields = 0;
  dateFields.forEach((field) => {
    if (fromDateDetails[field] !== toDateDetails[field]) unmatchedFields++;
  });
  let resultDateString = '';
  if (unmatchedFields >= Math.floor(dateFields.length / 2) + 1) {
    resultDateString = `${filterDateByDateFields(
      fromDateDetails,
      dateFields
    )} - ${filterDateByDateFields(toDateDetails, dateFields)}`;
  } else {
    dateFields.forEach((field) => {
      if (fromDateDetails[field] === toDateDetails[field])
        resultDateString += `${fromDateDetails[field]} `;
      else resultDateString += `${fromDateDetails[field]} - ${toDateDetails[field]} `;
    });
  }
  return resultDateString;
};
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const convertDateIntoDahedFormat = (data) => {
  var d = new Date(data);
  const date = [
    d.getFullYear(),
    ('0' + (d.getMonth() + 1)).slice(-2),
    ('0' + d.getDate()).slice(-2)
  ].join('-');
  return date;
};

export const convertDateToSlashedFormat = (data) => {
  var d = new Date(data);
  const date = [
    d.getFullYear(),
    ('0' + (d.getMonth() + 1)).slice(-2),
    ('0' + d.getDate()).slice(-2)
  ].join('/');
  return date;
};

export const convertDateToSlashedFormatDDMMYY = (data) => {
  var d = new Date(data);
  const date = [
    ('0' + d.getDate()).slice(-2),

    ('0' + (d.getMonth() + 1)).slice(-2),
    d.getFullYear()
  ].join('/');
  return date;
};

export const status = (res) => {
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res;
};

export const getServerResponse = async (res) => {
  if (!res.ok) {
    throw await res.json();
  }
  return res;
};

export const registerObserver = (ref, setShowImage) => {
  const observer = new IntersectionObserver((enteries, observer) => {
    enteries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      setShowImage(true);
      observer.disconnect();
    });
  });
  observer.observe(ref);
};

export const convertYYYYtoDD = (dateStr) => dateStr.split('/').reverse().join('/');

export const subDays = (totalDays = 10) => {
  var d = new Date();
  d.setDate(d.getDate() - totalDays);
  const newdate = convertDateToSlashedFormat(d);
  return newdate;
};

export const convertYYYYtoDDAndArabic = (dateStr, language) => {
  if (language === 'ar') {
    let arabicDateObj = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const newArabicDate = arabicDateObj.toLocaleDateString('ar-EG', options);
    return newArabicDate;
  }
  const formatteddate = dateStr.split('/').reverse().join('/');
  return formatteddate;
};

// Convert Degress to Radians
function Deg2Rad(deg) {
  return (deg * Math.PI) / 180;
}

function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lon1 = Deg2Rad(lon1);
  lon2 = Deg2Rad(lon2);
  var R = 6371; // km
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = lat2 - lat1;
  var d = Math.sqrt(x * x + y * y) * R;
  return d;
}

export const NearestStore = (latitude, longitude) => {
  var minDif = 99999;
  var closest = 0;
  const locationFromStorage = localStorage.getItem('location');
  if (Boolean(locationFromStorage)) {
    const location = JSON.parse(locationFromStorage);
    const storeFromStorage = localStorage.getItem('store');
    if (location?.latitude?.toFixed(1) === latitude?.toFixed(1) && Boolean(storeFromStorage)) {
      return JSON.parse(storeFromStorage);
    }
  } else {
    const currentlLocation = {
      latitude: latitude,
      longitude: longitude
    };
    localStorage.setItem('location', JSON.stringify(currentlLocation));
  }
  for (let index = 0; index < latlanginfo.length; ++index) {
    var dif = PythagorasEquirectangular(
      latitude,
      longitude,
      latlanginfo[index].lat,
      latlanginfo[index].lang
    );
    if (dif < minDif) {
      closest = index;
      minDif = dif;
    }
  }
  // echo the nearest city
  const store = { distanceInKm: parseFloat(minDif).toFixed(2), ...latlanginfo[closest] };
  localStorage.setItem('store', JSON.stringify(store));
  return store;
};

// Output numbers with leading zeroes
export const zeroPad = (num, places = 3) => {
  const numZeroes = places - num.toString().length + 1;
  if (numZeroes > 0) {
    return Array(+numZeroes).join('0') + num;
  }

  return num;
};
