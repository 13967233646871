import React, { useState, useRef, useEffect } from 'react';
import { registerObserver } from '../../../utils';
export default function Image({ src, alt, className, shouldSkip, skipRound }) {
  const [showImage, setShowImage] = useState(false);
  const placeHolderRef = useRef(null);
  // Component Did Mount.
  useEffect(() => {
    /**
     * To Register Observer on the span.
     */
    if (!shouldSkip) registerObserver(placeHolderRef.current, setShowImage);
  }, [shouldSkip]);

  if (showImage) {
    return (
      <>
        {skipRound ? (
          <img src={src} alt={alt} className={className} />
        ) : (
          <figure className={className} style={{ backgroundImage: `url(${src})` }}></figure>
        )}
      </>
    );
  }
  return <span ref={placeHolderRef} className={className} />;
}
