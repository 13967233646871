import React, { memo, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { FREQUENT_WORDS, PRODUCT_TYPES } from '../../constants';
import { useSelector } from 'react-redux';

const Videos = ({ productType }) => {
  const [play, setPlay] = useState(false);
  const { apiData } = useSelector(({ productReducers }) => productReducers);
  const { componentList } = apiData;

  const playPause = () => setPlay(!play);

  const videos = useMemo(() => {
    return (
      componentList?.length &&
      componentList?.filter((el) => el.title === FREQUENT_WORDS.ENGLISH.VIDEO)
    );
  }, [componentList]);

  return videos?.map((el, index) => (
    <div className="wrapper" key={index}>
      <ReactPlayer
        className="video"
        url={el.url}
        width="100%"
        height={PRODUCT_TYPES.SALMON === productType ? 300 : '100%'}
        playing={play}
        controls={true}
      />
      <div className={play ? 'pause' : 'play'} onClick={playPause}></div>
    </div>
  ));
};

export default memo(Videos);
