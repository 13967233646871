import React, { useEffect, useRef, memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import line1 from './dotted-lines/line1.svg';
import line2 from './dotted-lines/line2.svg';
import line3 from './dotted-lines/line3.svg';
import line4 from './dotted-lines/line4.svg';
import Image from '../common/Image';
import Figure from '../common/Figure';
import { TextSplitter } from '../common/TextSplitter';
import Map from './Map';

import ScrollAnimation from 'react-animate-on-scroll';
import '../../styles/animate.css';
import { animated, useSpring } from 'react-spring';
import { PRODUCT_TYPES, FREQUENT_WORDS, LANGUAGES } from '../../constants';
import { convertYYYYtoDDAndArabic } from '../../utils';
import ShowItemDates from '../common/ShowItemDates';
import Certificates from './Certificates';

const calc = (o) => `translateY(-${o * 0.1}px)`;
const calc2 = (o) => `translateY(-${o * 0.2}px)`;
const calc3 = (o) => `translateY(-${o * 0.05}px)`;
const calc4 = (o) => `translateY(-${o * 0.04}px)`;

const Components = ({
  lang,
  location,
  refParallax,
  productType,
  language,
  templeteTypes,
  productcode,
  componentList,
  isPilot
}) => {
  const myRefs = useRef([]);

  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    if (!Boolean(refParallax.current)) {
      return;
    }
    const posY = refParallax.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;

    set({ offset });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  //  Getting journies and position of last one
  let lastStepPosition = 0;
  let mapIndex = -2;
  let journies = [];

  const sortedSingleComponentList = componentList
    .sort((a, b) => a.position - b.position)
    .filter(
      (el) =>
        el.title &&
        el.title !== FREQUENT_WORDS.ENGLISH.JOURNEY_END &&
        el.title !== FREQUENT_WORDS.ENGLISH.VIDEO
    );
  if (sortedSingleComponentList) {
    // const nbComponents = sortedSingleComponentList.length;
    sortedSingleComponentList.filter((item, index) => {
      if (item.type.toLowerCase() === 'journey') {
        journies.push(index);
      }
      return null;
    });
    if (journies.length) {
      lastStepPosition = journies[journies.length - 1];
    }

    sortedSingleComponentList.forEach((item, index) => {
      if (item.type === 'ImageCaraousal' && item.title === 'ImageCaraousal1') {
        mapIndex = index;
      }
    });
  }

  let secondCarouselIndex = -2;
  sortedSingleComponentList.forEach((item, index) => {
    if (item.type === 'ImageCaraousal' && item.title === 'ImageCaraousal2') {
      secondCarouselIndex = index;
    }
  });

  return sortedSingleComponentList.map((item, index) => {
    return (
      <div key={index}>
        <div className="container">
          {item.position === 1 && (
            <>
              {productType === PRODUCT_TYPES.CHICKEN && (
                <>
                  <animated.div style={{ transform: offset.interpolate(calc2) }}>
                    <div className="beans2"></div>
                  </animated.div>

                  <animated.div style={{ transform: offset.interpolate(calc) }}>
                    <div className="beans1"></div>
                  </animated.div>

                  <animated.div
                    style={{
                      transform: offset.interpolate(calc3),
                      position: 'relative',
                      zIndex: 2
                    }}
                  >
                    <div className="beans3"></div>
                  </animated.div>

                  <animated.div
                    style={{
                      transform: offset.interpolate(calc4),
                      position: 'relative',
                      zIndex: 2
                    }}
                  >
                    <div className="beans4"></div>
                  </animated.div>
                </>
              )}

              <ScrollAnimation
                animateIn="fadeInUp"
                offset={80}
                afterAnimatedIn={(v) => {
                  if (v.inViewport) {
                    myRefs.current[index].className = 'dottedLine dottedLine1 active';
                  }
                }}
              >
                <div className="iconComponent">
                  <Image src={item.imageURL} alt="icon" loading="lazy" />
                  <div
                    className="dottedLine dottedLine1"
                    ref={(el) => (myRefs.current[index] = el)}
                  >
                    <img src={line1} alt="line1" />
                  </div>
                </div>
              </ScrollAnimation>
            </>
          )}

          {index - 1 === mapIndex && item.imageURL && (
            <ScrollAnimation
              animateIn="fadeInUp"
              offset={80}
              afterAnimatedIn={(v) => {
                if (v.inViewport) {
                  myRefs.current[index].className = 'dottedLine dottedLine2 active';
                }
              }}
            >
              <div
                className="iconComponent"
                style={{
                  marginTop: [PRODUCT_TYPES.GRAPES, PRODUCT_TYPES.EFPOMANEGGS].includes(productType)
                    ? 15
                    : 0
                }}
              >
                <Image src={item.imageURL} alt="icon" loading="lazy" />
                <div className="dottedLine dottedLine2" ref={(el) => (myRefs.current[index] = el)}>
                  <img src={line2} alt="line2" />
                </div>
              </div>
            </ScrollAnimation>
          )}
          {index === lastStepPosition && item.imageURL && (
            <ScrollAnimation
              animateIn="fadeInUp"
              offset={80}
              afterAnimatedIn={(v) => {
                if (v.inViewport) {
                  myRefs.current[index].className = `dottedLine dottedLine3 active ${
                    index % 2 === 0 ? 'flipped' : ''
                  }`;
                }
              }}
            >
              <div className="iconComponent">
                <Image src={item.imageURL} alt="icon" loading="lazy" />
                <div
                  className={`dottedLine dottedLine3 ${index % 2 === 0 ? 'flipped' : ''}`}
                  ref={(el) => (myRefs.current[index] = el)}
                >
                  <img src={line3} alt="line3" />
                </div>
              </div>
            </ScrollAnimation>
          )}
          {!(
            item.position === 1 ||
            index - 1 === mapIndex ||
            index === lastStepPosition ||
            (item.type === 'ImageCaraousal' && item.title === 'ImageCaraousal1')
          ) &&
            item.imageURL && (
              <ScrollAnimation
                animateIn="fadeInUp"
                offset={80}
                afterAnimatedIn={(v) => {
                  if (v.inViewport) {
                    myRefs.current[index].className = `dottedLine dottedline-even ${
                      index % 2 === 0 || index === secondCarouselIndex + 1 ? '' : 'flipped'
                    } active`;
                  }
                }}
              >
                <div className="iconComponent">
                  <Image src={item.imageURL} alt="icon" loading="lazy" />
                  <div
                    className={`dottedLine dottedline-even ${
                      index % 2 === 0 || index === secondCarouselIndex + 1 ? '' : 'flipped'
                    }`}
                    ref={(el) => (myRefs.current[index] = el)}
                  >
                    <img src={line4} alt="line4" />
                  </div>
                </div>
              </ScrollAnimation>
            )}
        </div>

        {item.type === 'ImageCaraousal' ? (
          <div>
            {item.title === 'ImageCaraousal1' ? (
              <div
                className={`carouselContainer ${
                  [PRODUCT_TYPES.EFPOMANEGGS].includes(productType) ? productType : ''
                }`}
                style={{ backgroundColor: 'transparent' }}
              >
                {productType === PRODUCT_TYPES.CHICKEN && (
                  <animated.div
                    style={{
                      transform: offset.interpolate(calc4),
                      position: 'relative',
                      zIndex: 2
                    }}
                  >
                    <div className="beans5"></div>
                  </animated.div>
                )}
                <ScrollAnimation
                  initiallyVisible={true}
                  animateIn="fadeIn"
                  offset={0}
                  afterAnimatedIn={(v) => {
                    if (v.inViewport) {
                      myRefs.current[index].className = 'dottedLine dottedLine4 active';
                    }
                  }}
                >
                  <div
                    className="dottedLine dottedLine4"
                    ref={(el) => (myRefs.current[index] = el)}
                  >
                    <img src={line4} alt="dotted lines" />
                  </div>
                </ScrollAnimation>
                <div className="carasoulRoundImg">
                  <Carousel
                    showThumbs={false}
                    swipeable={true}
                    centerMode={true}
                    centerSlidePercentage={100}
                    showArrows={false}
                  >
                    {item.images.map((imageVal, keyId) => (
                      <Figure className="roundImage" src={imageVal.url} key={keyId}></Figure>
                    ))}
                  </Carousel>
                </div>
                <Map
                  language={language}
                  templeteTypes={templeteTypes}
                  productType={productType}
                  pid={productcode}
                />
              </div>
            ) : (
              <>
                {item?.showType ? (
                  <div>
                    <h3 className="subComponentHeader"> {item.title}</h3>
                    <div className="carouselWithoutMap" key={index}>
                      {item.images.length !== 0 && (
                        <div className="carouselContainerWithouMap rectCarousel">
                          <Carousel
                            showThumbs={false}
                            swipeable={true}
                            centerMode={true}
                            centerSlidePercentage={100}
                            showArrows={false}
                          >
                            {item.images.length !== 0 &&
                              item.images.map((imageVal, keyId) => (
                                <Figure
                                  className="rectImage"
                                  src={imageVal.url}
                                  key={keyId}
                                  skipRound={item?.showType}
                                ></Figure>
                              ))}
                          </Carousel>
                          <div className="mapContainer1"></div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="carouselWithoutMap" key={index}>
                    {item.images.length !== 0 && (
                      <div className="carouselContainerWithouMap">
                        <Carousel
                          showThumbs={false}
                          swipeable={true}
                          centerMode={true}
                          centerSlidePercentage={100}
                          showArrows={false}
                        >
                          {item.images.length !== 0 &&
                            item.images.map((imageVal, keyId) => (
                              <Figure
                                className="roundImage"
                                src={imageVal.url}
                                key={keyId}
                              ></Figure>
                            ))}
                        </Carousel>
                        <div className="mapContainer1"></div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <ScrollAnimation animateIn="fadeIn" offset={80}>
            <div className="LayerUp">
              <div className="productLifecycleHeader">
                <h1>{item.title}</h1>
              </div>
              {(Boolean(item.dateFrom) || Boolean(item.dateTo)) && (
                <div className="productTextDescription centerContent">
                  {productType !== PRODUCT_TYPES.CHICKEN && (
                    <div>
                      {' '}
                      <ShowItemDates item={item} language={language} productType={productType} />
                    </div>
                  )}

                  {item.organisationName && (
                    <div className="productOraganisationContainer">
                      <p>{item.organisationName}</p>
                    </div>
                  )}
                </div>
              )}

              {Boolean(item.tableData) && (
                <table className="table">
                  <thead></thead>
                  <tbody>
                    {item.tableData.map((tabledata, key) => {
                      if (
                        isPilot &&
                        ['harvest year', 'best before'].includes(tabledata?.key?.toLowerCase())
                      ) {
                        return null;
                      }
                      return (
                        <tr key={key}>
                          <td className="thtd thKey">{tabledata.key}</td>
                          <td className="thtd thValue">
                            {' '}
                            <span className="test">
                              {Boolean(Date.parse(tabledata.val))
                                ? convertYYYYtoDDAndArabic(tabledata.val, language)
                                : tabledata.val}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {[
                PRODUCT_TYPES.IFFCOEGGS,
                PRODUCT_TYPES.EGGS,
                PRODUCT_TYPES.EFPOMANEGGS,
                PRODUCT_TYPES.GIPF_OMAN_EGGS
              ].includes(productType) &&
                item.journey === 'PULLET-REARING-FARM' &&
                templeteTypes?.breed && (
                  <TextSplitter
                    text={`<b>${lang === LANGUAGES.ARABIC.langCode ? 'النوع' : 'Breed'}: ${
                      templeteTypes?.breed
                    }</b>`}
                  />
                )}
              <TextSplitter text={item.description} />
            </div>
          </ScrollAnimation>
        )}

        <ScrollAnimation animateIn="fadeIn" offset={80}>
          <div className="container hide">
            {Boolean(item.subComponentList) &&
              item.subComponentList.map((el, id) => (
                <div key={id}>
                  <h3 className="subComponentHeader"> {el.title}</h3>
                  <TextSplitter text={el.description} />
                </div>
              ))}
            {item?.certificatesURL?.length > 0 && (
              <Certificates item={item} lang={lang} language={language} location={location} />
            )}
          </div>
        </ScrollAnimation>
      </div>
    );
  });
};

export default memo(Components);
