import React, { memo, useMemo } from 'react';
import { Input } from '@mafc/trolly';
import { FREQUENT_WORDS, LANGUAGES, PRODUCT_TYPES, VALIDATIONS, ERROR_CODE } from '../../constants';

const InputField = ({
  language,
  apiErrorCode,
  handleChange,
  pid,
  productType,
  batchCode,
  error,
  errorMsg,
  onLotNumberChange,
  lotNo
}) => {
  const FrequentWords =
    language === LANGUAGES.ARABIC.langCode ? FREQUENT_WORDS.ARABIC : FREQUENT_WORDS.ENGLISH;

  const label = useMemo(() => {
    switch (productType) {
      case PRODUCT_TYPES.CHICKEN:
        return FrequentWords.ITEM_NUMBER;
      case PRODUCT_TYPES.RTEAVOCADO:
        return FrequentWords.PACKING_CODE;
      default:
        return FrequentWords.LOT_NO;
    }
  }, [FrequentWords, productType]);

  const validationMsg = useMemo(() => {
    switch (productType) {
      case PRODUCT_TYPES.CHICKEN:
        return FrequentWords.INCORRECT_ITEM_NUMBER;
      case PRODUCT_TYPES.RTEAVOCADO:
        return FrequentWords.PACKING_CODE_VALIDATION_MSG;
      default:
        return FrequentWords.INCORRECT_LOT_NUMBER;
    }
  }, [FrequentWords, productType]);
  return (
    <>
      <div
        className={
          VALIDATIONS[productType]?.lotPrefixField
            ? 'inputContainer lotNumberContainer'
            : 'inputContainer'
        }
        style={VALIDATIONS[productType]?.lotPrefixField ? { backgroundColor: '#f3fafe' } : {}}
      >
        <Input
          label={
            <span
              dir="ltr"
              style={{
                paddingLeft: VALIDATIONS[productType]?.lotPrefixWidth
                  ? VALIDATIONS[productType]?.lotPrefixWidth - 15 + 'px'
                  : '0px',
                color: apiErrorCode === ERROR_CODE.INCORRECT_DATA ? '#F34041' : null,
                paddingRight: VALIDATIONS[productType]?.lotPostfixField
                  ? VALIDATIONS[productType]?.lotPostfixPadding -
                    VALIDATIONS[productType]?.labelPostfixPadding
                  : 'auto'
              }}
            >
              {label}
            </span>
          }
          icon={
            VALIDATIONS[productType]?.lotPrefixField && (
              <>
                <div
                  dir="ltr"
                  style={{
                    paddingLeft: '2px',
                    color: apiErrorCode === ERROR_CODE.INCORRECT_DATA ? '#F34041' : null
                  }}
                >
                  {' '}
                  {VALIDATIONS[productType]?.lotPrefixField + ' '}
                </div>
                {VALIDATIONS[productType]?.lotPostfixField ? (
                  <div
                    style={{
                      position: 'absolute',
                      right: 5,
                      color: apiErrorCode === ERROR_CODE.INCORRECT_DATA ? '#F34041' : null
                    }}
                  >
                    {' '}
                    {VALIDATIONS[productType]?.lotPostfixField + ' '}
                  </div>
                ) : null}
              </>
            )
          }
          type="text"
          variant={apiErrorCode === ERROR_CODE.INCORRECT_DATA ? 'error' : 'primary'}
          onChange={
            productType === PRODUCT_TYPES.CHICKEN
              ? !pid
                ? handleChange
                : undefined
              : onLotNumberChange
          }
          value={productType === PRODUCT_TYPES.CHICKEN ? pid || batchCode : lotNo}
          // backgroundColor='#f3fafe'
          style={{
            width:
              'calc(100% - ' +
              (VALIDATIONS[productType]?.lotPrefixWidth
                ? VALIDATIONS[productType]?.lotPrefixWidth
                : '0') +
              'px)',
            color: apiErrorCode === ERROR_CODE.INCORRECT_DATA ? '#F34041' : null,
            paddingRight: VALIDATIONS[productType]?.lotPostfixField
              ? VALIDATIONS[productType]?.lotPostfixPadding
              : 'auto',
            backgroundColor: VALIDATIONS[productType]?.lotPrefixField ? '' : '#f3fafe'
          }}
          maxLength={VALIDATIONS[productType]?.maxLength}
          data-testid="inputbox"
        />
      </div>
      {error && VALIDATIONS[productType]?.productField && (
        <p className="errorStart">
          {errorMsg === 'required' &&
            (language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.ITEM_NUMBER_ERROR
              : FREQUENT_WORDS.ENGLISH.ITEM_NUMBER_ERROR)}
          {errorMsg === 'minLength' &&
            (language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.ITEM_NUMBER_LENGTH_ERROR +
                ' ' +
                VALIDATIONS[productType]?.maxLength +
                ' ' +
                FREQUENT_WORDS.ARABIC.DIGITS
              : FREQUENT_WORDS.ENGLISH.ITEM_NUMBER_LENGTH_ERROR +
                ' ' +
                VALIDATIONS[productType]?.maxLength +
                ' ' +
                FREQUENT_WORDS.ENGLISH.DIGITS)}
        </p>
      )}
      {error && VALIDATIONS[productType]?.lotNo && (
        <p className="errorStart" data-testid="validation-error">
          {errorMsg === 'required' &&
            (language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.LOT_ERROR
              : FREQUENT_WORDS.ENGLISH.LOT_ERROR)}
          {errorMsg === 'minLength' &&
            (language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.LOT_NUMBER_ERROR +
                ' ' +
                VALIDATIONS[productType]?.lotMaxLength +
                ' ' +
                FREQUENT_WORDS.ARABIC.LOT_DIGITS
              : FREQUENT_WORDS.ENGLISH.LOT_NUMBER_ERROR +
                ' ' +
                VALIDATIONS[productType]?.lotMaxLength +
                ' ' +
                FREQUENT_WORDS.ENGLISH.LOT_DIGITS)}
        </p>
      )}
      {error && VALIDATIONS[productType]?.packingCode && (
        <p className="incorrect-input" data-testid="validation-error">
          {language === LANGUAGES.ARABIC.langCode
            ? FREQUENT_WORDS.ARABIC.PACKING_CODE_VALIDATION_MSG
            : FREQUENT_WORDS.ENGLISH.PACKING_CODE_VALIDATION_MSG}
        </p>
      )}
      {apiErrorCode === ERROR_CODE.INCORRECT_DATA && (
        <p className="incorrect-input">{validationMsg}</p>
      )}
    </>
  );
};

export default memo(InputField);
