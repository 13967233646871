import React, { memo, useMemo } from 'react';
import { storeDefaultLocation, PRODUCT_TYPES, LANGUAGES, MAPS } from '../../constants';
import defaultMap from '../../images/map-noLabel.png';
import spainMapEn from '../../images/map-spain-en.png';
import spainMapAr from '../../images/map-spain-ar.png';
import egyptMap from '../../images/map-egypt.png';
import egyptMapNoLabel from '../../images/map-egypt-noLabel.png';
import italyMap from '../../images/map-italy.jpg';
import omanMap from '../../images/oman-map.png';
import indiaMapAr from '../../images/map-india-ar.png';
import indiaMapEn from '../../images/map-india-en.png';

const Map = ({ language, productType, templeteTypes, pid }) => {
  const mapImage = useMemo(() => {
    let image = '';
    let marginTop = 0;
    let marginBottom = 0;
    switch (productType) {
      case PRODUCT_TYPES.HONEY:
        if (language === LANGUAGES.ARABIC.langCode) {
          image = spainMapAr;
        } else image = spainMapEn;
        break;
      case PRODUCT_TYPES.GRAPES:
      case PRODUCT_TYPES.KARSTENGRAPES:
        if (
          [
            6294017506349, 6294017506356, 6294017506363, 6294017502921, 6294017502914, 6294017502938
          ].includes(parseInt(pid))
        ) {
          image = italyMap;
        } else {
          image = egyptMap;
          marginTop = 70;
          marginBottom = 80;
        }
        break;
      case PRODUCT_TYPES.EFPOMANEGGS:
      case PRODUCT_TYPES.GIPF_OMAN_EGGS:
        image = omanMap;
        break;
      case PRODUCT_TYPES.INDIAGRAPES:
      case PRODUCT_TYPES.INDIA_MULHOUSE_GRAPES:
        image = language === 'ar' ? indiaMapAr : indiaMapEn;
        marginBottom = 10;
        break;
      case PRODUCT_TYPES.MEDI_GARDEN_GRAPES:
        image = MAPS[language].MEDI_GARDEN_GRAPES;
        break;
      case PRODUCT_TYPES.AGRICO_MUSHROOM:
        image = MAPS[language].AGRICO_MUSHROOM;
        marginTop = 50;
        break;
      case PRODUCT_TYPES.EGYPT_GRAPES:
        image = egyptMapNoLabel;
        marginTop = 80;
        break;
      default:
        image = defaultMap;
    }
    return (
      <img
        src={image}
        alt="map"
        width="100%"
        style={{ marginTop, marginBottom }}
        data-testid="mapImage"
      />
    );
  }, [language, productType, pid]);

  return (
    <div className="mapContainer">
      <div>{mapImage}</div>
      <div className="mapText">
        <span className="mapTextCenter">
          {productType === PRODUCT_TYPES.HYDROPONICS && templeteTypes.storeName
            ? templeteTypes.storeName
            : language === LANGUAGES.ARABIC.langCode
            ? storeDefaultLocation[`${productType}-storename-ar`]
            : storeDefaultLocation[`${productType}-storename-en`]}
        </span>
        <span></span>{' '}
        <div
          className={`maplocation ${
            [
              PRODUCT_TYPES.EFPOMANEGGS,
              PRODUCT_TYPES.EGYPT_GRAPES,
              PRODUCT_TYPES.GIPF_OMAN_EGGS,
              PRODUCT_TYPES.AGRICO_MUSHROOM
            ].includes(productType)
              ? productType
              : ''
          }`}
        >
          {productType === PRODUCT_TYPES.HYDROPONICS && templeteTypes.storeLocation
            ? templeteTypes.storeLocation
            : language === LANGUAGES.ARABIC.langCode
            ? storeDefaultLocation[`${productType}-storeLocation-ar`]
            : storeDefaultLocation[`${productType}-storeLocation-en`]}
        </div>
      </div>
      {![PRODUCT_TYPES.EGYPT_GRAPES].includes(productType) && <div className="mapDots"></div>}
    </div>
  );
};

export default memo(Map);
