export const API_URL =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? process.env.REACT_APP_API_URL_MPROD
    : process.env.REACT_APP_API_URL_MDEV;

export const API_URLS = {
  product: API_URL + '/api/GetBlockChainDetails',
  list: API_URL + '/api/masterList'
};

export const API_SOURCE =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? process.env.REACT_APP_PROD_SRC
    : process.env.REACT_APP_SRC;

const IMAGE_ENDPOINT = 'https://storbchain.blob.core.windows.net/product-images/uae';

// Todo: Remove old API Urls
// export const API_URL =
//   process.env.REACT_APP_ENVIRONMENT === 'prod'
//     ? process.env.REACT_APP_API_URL_PROD
//     : process.env.REACT_APP_API_URL_DEV;
// export const API_URLS = {
//   product: API_URL,
//   list: API_URL
// };

export const REGEX_USED = {
  NUMBER_FORMAT: /^[0-9]+$/,
  FORMAT: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
  ALPHANUMRICFORMAT: /^[a-zA-Z0-9_-]*$/,
  REMOVE_SLASH: /[/\\]/g
};
export const JOURNEYS = { GERMINATION: 'Germination' };
export const PRODUCT_TYPES = {
  HYDROPONICS: 'hydroponics',
  CHICKEN: 'chicken',
  FISH: 'fish',
  HONEY: 'honey',
  ORGANICCHICKEN: 'organic-chicken',
  COFFEE: 'coffee',
  MILK: 'milk', //UHT Milk
  EGGS: 'eggs', //Alghurair eggs
  GRAPES: 'grapes',
  ORGANICCARROTS: 'organic-carrots',
  ORGANICCUCUMBER: 'organic-cucumber',
  EFPOMANEGGS: 'efp-oman-eggs', //Modern Poultry farm
  FRESHMILK: 'fresh-milk', //Fresh Milk
  IFFCOEGGS: 'iffco-eggs', //IFFCO Eggs
  INDIAGRAPES: 'india-grapes', //Grapes from Eurofruits & Socomo India
  SALADLEAVES: 'salad-leaves',
  LABAN: 'laban',
  KARSTENGRAPES: 'karsten-grapes', // Egypt
  RTEAVOCADO: 'rte-avocado',
  ELITEPOTATO: 'elite-agro-potato',
  ELITEPOTATO_PROMO: 'elite-agro-potato-promo',
  MEDI_GARDEN_GRAPES: 'medi-garden-grapes', // Labanon
  PURE_HARVEST_TOMATO: 'pure-harvest-tomato',
  SALMON: 'smoked-salmon',
  MUSHROOM: 'mushroom',
  AGRICO_MUSHROOM: 'agrico-mushroom',
  STRAWBERRY: 'strawberry',
  BLUEBERRY: 'blueberry',
  EGYPT_GRAPES: 'egypt-grapes', //Daltex-grapes
  SEABREAM_FISH: 'seabream-fish-crown-farms',
  GIPF_OMAN_EGGS: 'gipf-oman-eggs',
  HAYATNA_MILK: 'hayatna-milk',
  INDIA_MULHOUSE_GRAPES: 'india-mulhouse-grapes'
};
export const DEAFULT_STORE = 'maf';

export const mappedMonths = (language) => {
  const engMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const arabicMonths = [
    'يناير',
    'فبراير',
    'مارس',
    'إبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر'
  ];
  const months = language?.startsWith('ar') ? arabicMonths : engMonths;
  const mp = {};
  for (let currMonth = 0; currMonth < months.length; currMonth++) mp[currMonth] = months[currMonth];
  return mp;
};
export const FREQUENT_WORDS = {
  ARABIC: {
    TRY_AGAIN: 'حاول مرة أخري',
    SCAN: 'مسح منتج آخر',
    PRODUCTION_CODE: 'لطفا أختر المنتج',
    PRODUCTION_CODE_AND_DATE: 'أدخل رمز العنصر وتاريخ الإنتاج أدناه',
    PRODUCTION_CODE_STORE_AND_DATE: 'أدخل رمز العنصر ومتجر وتاريخ الإنتاج أدناه',
    PRODUCTION_STORE_AND_DATE: 'حدد المتجر وأدخل تاريخ الإنتاج أدناه',
    CHICKEN_TYPE_AND_PRODUCTION_DATE: 'اختر قطعة الدجاج وأدخل تاريخ الإنتاج',
    PRODUCTION_LOT_DATE: 'أدخل رقم الدفعة وتاريخ الإنتاج أدناه',
    PRODUCTION_LOT: 'لطفاً ادخل رقم التشغيلة',
    COUNTRY_PRODUCTION_LOT: 'لطفاً ادخل رقم التشغيلة واختر البلد.',
    LOT_NO: 'رقم التشغيلة',
    PRODUCTION_DATE: 'أدخل تاريخ التعبئة أدناه',
    PRODUCTION_DATE_TITLE: 'لطفاً أدخل تاريخ الإنتاج',
    ITEM_NUMBER: 'رمز الصنف',
    ERROR: 'الرجاء إدخال رمز الدفعة الصحيح',
    CONTINUE: 'استمر',
    NO_INFO: 'لا توجد معلومات متاحة لهذا المنتج أو التاريخ',
    SHARE: 'شارك',
    SELECT_HARVESTING: 'حدد تاريخ الحصاد',
    HARVESTING_DATE: 'حدد تاريخ الحصاد',
    NEXT_TODO: 'ماذا تريد ان تفعل لاحقا ؟',
    ERROR_MESSAGE: 'أوه لا ، حدث خطأ ما!',
    SELECT_STORE: 'حدد المتجر',
    ITEM_NUMBER_ERROR: 'الرجاء إدخال رمز المنتج',
    ITEM_NUMBER_LENGTH_ERROR: 'يجب أن يحتوي رمز المنتج على',
    DIGITS: 'أرقام',
    LOT_ERROR: 'الرجاء إدخال رقم اللوت',
    LOT_NUMBER_ERROR: 'يجب أن يحتوي رقم الدفعة',
    LOT_DIGITS: 'أحرف أبجدية رقمية',
    DATE_ERROR: 'تاريخ الإنتاج مطلوب',
    INCORRECT_INFO_MSG:
      'عذراً، يبدو أنك أدخلت معلومات غير صحيحة. الرجاء إدخال التفاصيل مرة أخرى للمتابعة.',
    INCORRECT_ITEM_NUMBER: 'الرجاء إدخال رقم المنتج الصحيح',
    INCORRECT_LOT_NUMBER: 'الرجاء إدخال رقم الدفعة الصحيح',
    INCORRECT_DATE: 'الرجاء إدخال التاريخ الصحيح',
    CLOSE: 'إغلاق',
    START_OF_ANALYSIS: 'بداية التحليل:',
    END_OF_ANALYSIS: 'نهاية التحليل:',
    LOT_NO_GRAPES_TYPES: 'لطفا , أدخل رقم التشغيلة ونوع العنب.',
    PACKING_CODE_VALIDATION_MSG: 'لطفاً أدخل كود التعبئة الصحيح',
    ENTER_PACKING_CODE: 'لطفاً أدخل كود التعبئة',
    PACKING_CODE: 'كود التعبئة',
    SELECT_PACKING_DATE: 'لطفا اختر تاريخ التعبئة',
    DROPDOWN_TEXT: 'اضغط هنا لتعرف اكثر'
  },
  ENGLISH: {
    TRY_AGAIN: 'Try Again',
    SCAN: 'Scan Another Product',
    PRODUCTION_CODE: 'Please select the product',
    PRODUCTION_CODE_AND_DATE: 'Enter the Item code and date of production below',
    PRODUCTION_CODE_STORE_AND_DATE: 'Enter the Item code, store and date of production below',
    PRODUCTION_STORE_AND_DATE: 'Select the store and enter the production date below',
    CHICKEN_TYPE_AND_PRODUCTION_DATE: 'Select the chicken part and enter the production date',
    PRODUCTION_LOT: 'Enter the lot number below',
    COUNTRY_PRODUCTION_LOT: 'Please enter lot number & select the country',
    PRODUCTION_LOT_DATE: 'Enter the lot number and date of production below',
    PRODUCTION_DATE: 'Enter the date of packaging below',
    PRODUCTION_DATE_TITLE: 'Please enter the date of production below',
    ITEM_NUMBER: 'Item Number ',
    LOT_NO: 'Lot Number',
    CONTINUE: 'CONTINUE',
    NO_INFO: 'There is no information available for this product or date.',
    SHARE: 'Share',
    SELECT_HARVESTING: 'Select harvesting date',
    HARVESTING_DATE: 'Harvesting date',
    JOURNEY_END: 'Jouney end Component',
    VIDEO: 'video',
    NEXT_TODO: 'What do you want to do next ?',
    ERROR_MESSAGE: 'Oh no, Something Went wrong!',
    SELECT_STORE: 'Select Store',
    ITEM_NUMBER_ERROR: 'Please enter the Product Code',
    ITEM_NUMBER_LENGTH_ERROR: 'Product Code must contain',
    DIGITS: 'digits',
    LOT_ERROR: 'Please enter the Lot number',
    LOT_NUMBER_ERROR: 'Lot number must contain',
    LOT_DIGITS: 'alphanumeric characters',
    DATE_ERROR: 'Production date is required',
    INCORRECT_INFO_MSG:
      'Sorry, looks like you’ve entered incorrect information. Please enter the details again to proceed.',
    INCORRECT_ITEM_NUMBER: 'Please enter the correct item number',
    INCORRECT_LOT_NUMBER: 'Please enter the correct lot number',
    INCORRECT_DATE: 'Please enter the correct date',
    CLOSE: 'Close',
    START_OF_ANALYSIS: 'Start of analysis:',
    END_OF_ANALYSIS: 'End of analysis:',
    LOT_NO_GRAPES_TYPES: 'Kindly, enter lot number & Grapes type',
    PACKING_CODE_VALIDATION_MSG: 'Please enter correct packing code',
    ENTER_PACKING_CODE: 'Please enter the packing code',
    PACKING_CODE: 'Packing Code',
    SELECT_PACKING_DATE: 'Please select packing date',
    DROPDOWN_TEXT: 'Press to know more'
  }
};

export const LANGUAGES = {
  ENGLISH: {
    langCode: 'en',
    language: 'English',
    langConvertCode: 'en-US'
  },
  ARABIC: {
    langCode: 'ar',
    language: 'عربى',
    langConvertCode: 'ar-EG'
  }
};
// export const LANGUAGES = [
//   {
//     language: 'English',
//     langCode: 'en'
//   },

//   {
//     language: 'Arabic',
//     langCode: 'ar'
//   }
// ];
export const SCANDIT_KEY = `AVZPzThsGhkhLUEhrw/9diIJ0uLtD4SiPUswbNBqP7gFLZj9aF+nfvFzJDPsPkchR24dO5R2LX+9Ytsqnk7XOTtKdN3le6KuoEimr0lBV/h/XhcKDVHtGSlumiYpWl1wDHWM/ORpEzrWd/tLUW7tKmQKpP++C/7GNkaW6ac2lCIiLoCshmKURE3IjJVcc7B3DLcNvwPifyFX77c3gLAtAu4vta+dJa/9f369ffM20kXHAc7Qa1ukrlth2CliUJBM9leGgEzNckSld3mvrSxDsXNoXHUH+g7PtmtQVrRS2+4PO/vIsrZI8t/BLUdNZ2XCyw9CfVa8uU8LW5Rr8k1nwXEOWfeXFKmJzGsbkb9i3l/CW0H+JjGwOKQzQFdtcA482syVBn7LIv5uXk7ZxE0I0PpVWBO62wmYmq6R0zusYi3VjiIP1TJ6/YAsNKztQGVWgjkwv4Kudt0+b+4jHRGMboYOQlHX6P6t1eplyIn0tA9N1HgR6grHNJb2ZGsIkyEe+q6SjfFlerlwojtaM6xh0Dk8DQWlkF8S6wur/7Pya+ZMCtniC3cgxh6ZB4cUinYgpJuvxx+1/OGsD/+HVq82YRLoLyv3dm4lIf7te0h6AGI1D7nTLEkVT/v7yC1i06vGbxM15c2B+dLrnAqhzZr89QzQIdPOwMmy9gQ6+ZjgWmvZmqeHg9xdz5K4sugVflRHsKPTBP2AapNTXaJ0grqdVgD/0sn8zIjZAGS8xOc1ZcHzykE/YBoOeuOjkiM+AWrCjbz5rf+8swL30eiXTqHYpmmoVCy2aNop53aQM4Cra/ciV3h68RT+qWRyS2QkcT3dqRtDZ1Bc0QgZJupZDbY=`;

export const storeDefaultLocation = {
  'hydroponics-storename-ar': 'دبي',
  'hydroponics-storename-en': 'Dubai',
  'fish-storename-ar': 'جبل علي',
  'fish-storename-en': 'Jebel Ali',
  'chicken-storename-ar': 'أبو ظبي',
  'chicken-storename-en': 'Abu Dhabi',
  'hydroponics-storeLocation-ar': 'الإمارات العربية المتحدة',
  'hydroponics-storeLocation-en': 'United Arab Emirates',
  'fish-storeLocation-ar': 'دبي',
  'fish-storeLocation-en': 'Dubai',
  'chicken-storeLocation-ar': 'الإمارات العربية المتحدة',
  'chicken-storeLocation-en': 'United Arab Emirates',
  'organic-chicken-storename-ar': 'العين',
  'organic-chicken-storename-en': 'Al Ain',
  'organic-chicken-storeLocation-ar': 'الإمارات العربية المتحدة',
  'organic-chicken-storeLocation-en': 'United Arab Emirates',
  'coffee-storename-ar': 'دبي',
  'coffee-storename-en': 'Dubai',
  'coffee-storeLocation-ar': 'الإمارات العربية المتحدة',
  'coffee-storeLocation-en': 'United Arab Emirates',
  'eggs-storeLocation-en': 'Ras Al-Khaimah',
  'eggs-storeLocation-ar': 'رأس الخيمة',
  'organic-carrots-storeLocation-en': 'Al Ain',
  'organic-carrots-storeLocation-ar': 'العين',
  'organic-cucumber-storeLocation-en': 'Al Ain',
  'organic-cucumber-storeLocation-ar': 'العين',
  'milk-storeLocation-en': 'Al-Ahsa',
  'milk-storeLocation-ar': 'الأحساء',
  'efp-oman-eggs-storeLocation-en': 'Barka',
  'efp-oman-eggs-storeLocation-ar': 'بركاء',
  'fresh-milk-storeLocation-en': 'Al Ain',
  'fresh-milk-storeLocation-ar': 'العين',
  'salad-leaves-storeLocation-en': 'Al Ain',
  'salad-leaves-storeLocation-ar': 'العين',
  'iffco-eggs-storeLocation-en': 'Ajman',
  'iffco-eggs-storeLocation-ar': 'عجمان',
  'laban-storeLocation-en': 'Al Ain',
  'laban-storeLocation-ar': 'العين',
  'elite-agro-potato-storeLocation-en': 'Al Ain',
  'elite-agro-potato-storeLocation-ar': 'العين',
  'pure-harvest-tomato-storeLocation-en': 'Al Ain',
  'pure-harvest-tomato-storeLocation-ar': 'العين',
  'smoked-salmon-storeLocation-ar': 'دبى',
  'smoked-salmon-storeLocation-en': 'Dubai',
  'mushroom-storeLocation-en': 'Ras Al-Khaimah',
  'mushroom-storeLocation-ar': 'رأس الخيمة',
  'agrico-mushroom-storeLocation-en': 'Al Khor',
  'agrico-mushroom-storeLocation-ar': 'الخور',
  'strawberry-storeLocation-en': 'Al Ain',
  'strawberry-storeLocation-ar': 'العين',
  'blueberry-storeLocation-en': 'Abu Dhabi',
  'blueberry-storeLocation-ar': 'أبو ظبى',
  'egypt-grapes-storeLocation-en': 'Al Nubaria',
  'egypt-grapes-storeLocation-ar': 'النوباريه',
  'seabream-fish-crown-farms-storeLocation-en': 'Fujairah',
  'seabream-fish-crown-farms-storeLocation-ar': 'الفجيرة',
  'rte-avocado-storename-ar': 'دبي',
  'rte-avocado-storename-en': 'Dubai',
  'gipf-oman-eggs-storeLocation-en': 'Dhank',
  'gipf-oman-eggs-storeLocation-ar': 'ضنك',
  'hayatna-milk-storename-en': 'Al Ain',
  'hayatna-milk-storename-ar': 'العين'
};

export const HYDROPONICS_STORES = [
  {
    id: '073',
    en: 'Ibn Batuta Mall',
    ar: 'ابن بطوطة مول'
  },
  {
    id: '064',
    en: "City Centre Me'aisem",
    ar: 'سيتي سنتر معيصم'
  },
  {
    id: '072',
    en: 'Dubai Festival City',
    ar: 'دبي فيستيفال سيتي'
  },
  {
    id: '015',
    en: 'Mirdif City Center',
    ar: ' سيتي سنتر مردف'
  },
  {
    id: '837',
    en: 'Carrefour Market Al Wasl',
    ar: 'كارفور ماركت الوصل'
  },
  {
    id: '067',
    en: 'Masdar City Center',
    ar: 'ماي سيتي سنتر مصدر'
  },
  {
    id: '074',
    en: 'Carrefour YAS Mall',
    ar: 'كارفور ياس مول'
  }
  // {
  //   "id": '843',
  //   "en": "Carrefour Market Dubiotech",
  //   "ar": "كارفور ماركت دوبيوتيك ",
  // }
];

export const VALIDATIONS = {
  [PRODUCT_TYPES.HYDROPONICS]: {
    required: true,
    minLength: 7,
    // maxLength: 7,
    productField: false,
    date: true
  },
  [PRODUCT_TYPES.CHICKEN]: {
    required: true,
    // input: true,
    // minLength: 4,
    // maxLength: 4,
    productField: true
  },
  [PRODUCT_TYPES.ORGANICCHICKEN]: {
    required: true,
    minLength: 7,
    // maxLength: 7,
    date: true,
    productSelect: true
  },
  [PRODUCT_TYPES.HONEY]: {
    required: true,
    input: true,
    // minLength: 13,
    // maxLength: 13,
    lotNo: true,
    lotPrefixField: 'L-',
    lotPrefix: '',
    lotMaxLength: 9,
    productField: false
  },
  [PRODUCT_TYPES.COFFEE]: {
    required: true,
    input: false
    // maxLength: 13
  },
  [PRODUCT_TYPES.FISH]: {
    required: true,
    // maxLength: 7,
    date: true,
    productField: false
  },
  [PRODUCT_TYPES.EGGS]: {
    required: true,
    input: true,
    // maxLength: 13,
    date: true,
    minDays: 90,
    lotNo: true,
    lotPrefixField: 'AGFP UAE',
    lotPrefix: 'AGFPUAE',
    lotPrefixWidth: '80',
    lotMaxLength: 9,
    productField: false
  },
  [PRODUCT_TYPES.GRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.ORGANICCARROTS]: {
    required: true,
    input: true,
    lotNo: true,
    lotPrefixField: 'CRT',
    lotPrefix: 'CRT',
    lotPrefixWidth: '35'
  },
  [PRODUCT_TYPES.ORGANICCUCUMBER]: {
    required: true,
    input: true,
    lotNo: true,
    lotPrefixField: 'CUC',
    lotPrefix: 'CUC',
    lotPrefixWidth: '35'
  },
  [PRODUCT_TYPES.MILK]: {
    required: true,
    input: true,
    date: true,
    minDays: 186,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.EFPOMANEGGS]: {
    required: true,
    input: true,
    date: true,
    minDays: 90,
    lotNo: true,
    lotPrefix: 'CRF',
    lotPrefixField: 'CRF',
    lotPrefixWidth: '35',
    lotPostfix: 'OM',
    lotPostfixField: 'OM',
    lotPostfixPadding: 35,
    labelPostfixPadding: 10,
    productField: false
  },
  [PRODUCT_TYPES.FRESHMILK]: {
    required: true,
    date: true,
    minDays: 14,
    productField: false
  },
  [PRODUCT_TYPES.IFFCOEGGS]: {
    required: true,
    input: true,
    date: true,
    minDays: 90,
    lotNo: true,
    lotPrefixField: 'CRF',
    lotPrefix: 'CRF',
    lotPrefixWidth: 35,
    lotPostfix: 'UAE',
    lotPostfixField: 'UAE',
    lotPostfixPadding: 40,
    labelPostfixPadding: 10,
    productField: false
  },
  [PRODUCT_TYPES.INDIAGRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    lotPrefixField: 'L ',
    lotPrefix: 'L ',
    lotPrefixWidth: 18,
    productField: false
  },
  [PRODUCT_TYPES.SALADLEAVES]: {
    required: true,
    input: true,
    lotNo: true,
    lotPrefixField: 'PH',
    lotPrefix: 'PH',
    lotPrefixWidth: '30',
    productField: false
  },
  [PRODUCT_TYPES.LABAN]: {
    required: true,
    date: true,
    minDays: 14,
    productField: false
  },
  [PRODUCT_TYPES.KARSTENGRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.RTEAVOCADO]: {
    required: true,
    input: true,
    lotNo: false, // packing code
    packingCode: true, // packing code
    productField: false
  },
  [PRODUCT_TYPES.ELITEPOTATO]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.ELITEPOTATO_PROMO]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.MEDI_GARDEN_GRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.PURE_HARVEST_TOMATO]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false,
    lotPrefixField: 'PH',
    lotPrefix: 'PH',
    lotPrefixWidth: '30'
  },
  [PRODUCT_TYPES.SALMON]: {
    required: true,
    date: true,
    minDays: 31,
    productField: false
  },
  [PRODUCT_TYPES.MUSHROOM]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.AGRICO_MUSHROOM]: {
    required: true,
    date: true,
    productField: false
  },
  [PRODUCT_TYPES.GIPF_OMAN_EGGS]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false,
    date: true
  },
  [PRODUCT_TYPES.STRAWBERRY]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false,
    lotPrefixField: 'PH',
    lotPrefix: 'PH',
    lotPrefixWidth: '30'
  },
  [PRODUCT_TYPES.BLUEBERRY]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  },
  [PRODUCT_TYPES.EGYPT_GRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    lotPrefixField: 'L:',
    lotPrefix: 'L:',
    lotPrefixWidth: '20',
    date: false,
    productField: false
  },
  [PRODUCT_TYPES.SEABREAM_FISH]: {
    required: true,
    input: false,
    lotNo: false,
    date: false,
    productField: false
  },
  [PRODUCT_TYPES.HAYATNA_MILK]: {
    required: true,
    date: true,
    minDays: 186,
    productField: false
  },
  [PRODUCT_TYPES.INDIA_MULHOUSE_GRAPES]: {
    required: true,
    input: true,
    lotNo: true,
    productField: false
  }
};

export const ERROR_CODE = {
  INCORRECT_DATA: 2001
};

export const EGGS_LANDING_IMAGE = `${IMAGE_ENDPOINT}/6290410010605/Landing-page.png`;
export const GRAPES_LANDING_IMAGE = `${IMAGE_ENDPOINT}/6294017506103/6294017506103_landing_page.png`;
export const ORGANIC_CARROTS_LANDING_IMAGE = `${IMAGE_ENDPOINT}/2000017796888/2000017796888_landing_page.png`;
export const ORGANIC_CUCUMBER_LANDING_IMAGE = `${IMAGE_ENDPOINT}/2000017882697/2000017882697_landing_page.png`;
export const UHT_MILK_LANDING_IMAGE = `${IMAGE_ENDPOINT}/2067267000219/2067267000219_landing_page.png`;
export const SALAD_LEAVES_LANDING_IMAGE = `${IMAGE_ENDPOINT}/6294017507445/Landing-Page.png`;
export const IFFCO_EGGS_LANDING_IMAGE = `${IMAGE_ENDPOINT}/eggs/IFFCO/landing-page.png`;
export const INDIA_GRAPES_LANDING_IMAGE = `${IMAGE_ENDPOINT}/6294017502433/Landing%20page.png`;
export const RTE_AVOCADO_LANDING_IMAGE = `${IMAGE_ENDPOINT}/avocado/icon/6294017502006_landing_page.png`;
export const EGYPT_GRAPES_LANDING_IMAGE = `${IMAGE_ENDPOINT}/grapes/daltex/##pid##_landing.jpg`;

export const MAPS = {
  en: {
    RTEAVOCADO: `${IMAGE_ENDPOINT}/avocado/Nairobi_map_en.png`,
    MEDI_GARDEN_GRAPES: `${IMAGE_ENDPOINT}/grapes/Lebanon-MediGarden/lebnon-Map_Eng.jpg`,
    AGRICO_MUSHROOM: `${IMAGE_ENDPOINT}/mushroomq/map.jpg`
  },
  ar: {
    RTEAVOCADO: `${IMAGE_ENDPOINT}/avocado/Nairobi_map_en.png`,
    MEDI_GARDEN_GRAPES: `${IMAGE_ENDPOINT}/grapes/Lebanon-MediGarden/lebnon-Map_Arb.jpg`,
    AGRICO_MUSHROOM: `${IMAGE_ENDPOINT}/mushroomq/map.jpg`
  }
};

export const ITALY_GRAPES_2023 = {
  en: [
    {
      id: '6294017502921',
      label: 'RED GRAPES'
    },
    {
      id: '6294017502914',
      label: 'WHITE GRAPES'
    },
    {
      id: '6294017502938',
      label: 'BLACK GRAPES'
    }
  ],
  ar: [
    {
      id: '6294017502921',
      label: 'عنب أحمر'
    },
    {
      id: '6294017502914',
      label: 'عنب أبيض'
    },
    {
      id: '6294017502938',
      label: 'عنب أسود'
    }
  ]
};

export const COUNTRY_CODES = {
  ar: [
    {
      id: 'ARE',
      label: 'الامارات'
    },
    {
      id: 'SAU',
      label: 'السعودية'
    }
    // Not supporting JOR with name carrefour
    // {
    //   id: 'JOR',
    //   label: 'الاردن'
    // }
  ],
  en: [
    {
      id: 'ARE',
      label: 'United Arab Emirates'
    },
    {
      id: 'SAU',
      label: 'Saudi Arabia'
    }
    // Not supporting JOR with name carrefour
    // {
    //   id: 'JOR',
    //   label: 'Jordan'
    // }
  ]
};

export const CHICKEN_TYPES = {
  ar: [
    {
      id: '1334',
      label: 'سيقان دجاج طازجة 500 غ'
    },
    {
      id: '1335',
      label: 'سيقان دجاج طازجة 1000 غ'
    },
    {
      id: '1345',
      label: 'اجنحة دجاج طازجة 500 غ'
    },
    {
      id: '1344',
      label: 'اجنحة دجاج طازجة 1000 غ'
    },
    {
      id: '1338',
      label: 'ارجل دجاج طازجة كاملة 500 غ'
    },
    {
      id: '1337',
      label: 'ارجل دجاج طازجة كاملة 1000 غ'
    },
    {
      id: '1343',
      label: 'أفخاذ دجاج طازجة 500 غ'
    },
    {
      id: '1341',
      label: 'دجاج كامل طازج مقطع بدون جلد 1 كغ'
    },
    {
      id: '1333',
      label: 'صدور دجاج طازجة 500 غ'
    },
    {
      id: '1332',
      label: 'صدور دجاج طازجة 1000 غ'
    },
    {
      id: '1315',
      label: 'دجاج طازج  2*500 غ'
    },
    {
      id: '1316',
      label: 'دجاج طازج  2*600 غ'
    },
    {
      id: '1317',
      label: 'دجاج طازج 700 غ'
    },
    {
      id: '1318',
      label: 'دجاج طازج  2*700 غ'
    },
    {
      id: '1323',
      label: 'دجاج طازج 800 غ'
    },
    {
      id: '1325',
      label: 'دجاج طازج 900 غ'
    },
    {
      id: '1322',
      label: 'دجاج طازج 1000 غ'
    },
    {
      id: '1319',
      label: '	دجاج طازج 1100 غ'
    },
    {
      id: '1320',
      label: 'دجاج طازج 1200 غ'
    },
    {
      id: '1321',
      label: 'دجاج طازج 1300 غ'
    }
  ],
  en: [
    {
      id: '1334',
      label: 'CRF Fresh Chicken Drumsticks 500g'
    },
    {
      id: '1335',
      label: 'CRF Fresh Chicken Drumsticks 1 KG'
    },
    {
      id: '1345',
      label: 'CRF Fresh Chicken Wings 500g'
    },
    {
      id: '1344',
      label: 'CRF Fresh Chicken Wings 1 KG'
    },
    {
      id: '1338',
      label: 'CRF Fresh Chicken LEG 500g'
    },
    {
      id: '1337',
      label: 'CRF Fresh Chicken LEG 1 KG'
    },
    {
      id: '1343',
      label: 'CRF Fresh Chicken Thigh 500g'
    },
    {
      id: '1341',
      label: 'CRF Fresh Chicken Skinless CUT UP 500g'
    },
    {
      id: '1333',
      label: 'CRF FRESH CHICKEN Breast 500g'
    },
    {
      id: '1332',
      label: 'CRF FRESH CHICKEN Breast 1 KG'
    },
    {
      id: '1315',
      label: 'CRF FRESH BABY CHICKEN 500GX2'
    },
    {
      id: '1316',
      label: 'CRF FRESH BABY CHICKEN 600GX2'
    },
    {
      id: '1317',
      label: 'CRF FRESH BABY CHICKEN 700G'
    },
    {
      id: '1318',
      label: 'CRF FRESH BABY CHICKEN 700GX2'
    },
    {
      id: '1323',
      label: 'CRF FRESH CHICKEN 800G'
    },
    {
      id: '1325',
      label: 'CRF FRESH CHICKEN 900G'
    },
    {
      id: '1322',
      label: 'CRF FRESH CHICKEN 1 KG'
    },
    {
      id: '1319',
      label: 'CRF FRESH CHICKEN 1.1KG'
    },
    {
      id: '1320',
      label: 'CRF FRESH CHICKEN 1.2KG'
    },
    {
      id: '1321',
      label: 'CRF FRESH CHICKEN 1.3KG'
    }
  ]
};
