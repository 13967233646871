import React, { useState, useRef, useEffect } from 'react';
import { registerObserver } from '../../utils';
export default function Image({ src, alt, className, dataTestId }) {
  const [showImage, setShowImage] = useState(false);
  const placeHolderRef = useRef(null);
  // Component Did Mount.
  useEffect(() => {
    /**
     * To Register Observer on the span.
     */
    registerObserver(placeHolderRef.current, setShowImage);
  }, []);

  if (showImage) {
    return (
      <img
        data-testid={dataTestId || 'Image'}
        src={src}
        alt={alt}
        className={className}
        onError={() => setShowImage(false)}
      />
    );
  }
  return <span data-testid={dataTestId || 'Image'} ref={placeHolderRef} className={className} />;
}
