export const latlanginfo = [
  {
    id: 2,
    name: 'Carrefour Ajman City Center',
    lat: 25.399895,
    lang: 55.479792
  },
  {
    id: 7,
    name: 'Carrefour Jimi Mall',
    lat: 24.243319,
    lang: 55.726911
  },
  {
    id: 6,
    name: 'Carrefour Marina Mall',
    lat: 24.476258,
    lang: 54.322318
  },
  {
    id: 61,
    name: 'Carrefour Deerfields',
    lat: 24.523681,
    lang: 54.670974
  },
  {
    id: 16,
    name: 'Carrefour Baniyas',
    lat: 24.312167,
    lang: 54.620751
  },
  {
    id: 12,
    name: 'Carrefour Bawadi Mall',
    lat: 24.160617,
    lang: 55.807156
  },
  {
    id: 4,
    name: 'Carrefour Aiport Road Al Saqr',
    lat: 24.423693,
    lang: 54.452072
  },
  {
    id: 14,
    name: 'Carrefour Dalma Mall',
    lat: 24.333136,
    lang: 54.52344
  },
  {
    id: 63,
    name: 'Carrefour Al Ghurair',
    lat: 25.267674,
    lang: 55.317396
  },
  {
    id: 19,
    name: 'Carrefour Madina Mall',
    lat: 25.282073,
    lang: 55.398013
  },
  {
    id: 10,
    name: 'Carrefour Century Mall',
    lat: 25.289789,
    lang: 55.347052
  },
  {
    id: 15,
    name: 'Carrefour Mirdif City Center',
    lat: 25.216334,
    lang: 55.407789
  },
  {
    id: 62,
    name: 'Carrefour Burjuman',
    lat: 25.253135,
    lang: 55.302175
  },
  {
    id: 9,
    name: 'Carrefour Shindagha City Center',
    lat: 25.264225,
    lang: 55.287049
  },
  {
    id: 11,
    name: 'Carrefour Mall of the Emirates',
    lat: 25.11986,
    lang: 55.202368
  },
  {
    id: 64,
    name: 'Carrefour Meaisem City Center',
    lat: 25.040672,
    lang: 55.196874
  },
  {
    id: 3,
    name: 'Carrefour City Center Deira',
    lat: 25.252176,
    lang: 55.333335
  },
  {
    id: 60,
    name: 'Carrefour Fujairah City Center',
    lat: 25.1255571,
    lang: 56.3021354
  },
  {
    id: 5,
    name: 'Carrefour Manar Mall',
    lat: 25.783487,
    lang: 55.96546
  },
  {
    id: 17,
    name: 'Carrefour Safeer Mall (Name Changed to RAK AL NAEEM CITY CENTER)',
    lat: 25.74963,
    lang: 55.92494
  },
  {
    id: 8,
    name: 'Carrefour Sharjah City Center',
    lat: 25.325,
    lang: 55.393517
  },
  {
    id: 832,
    name: 'Carrefour Market Rimal Mall',
    lat: 24.204567,
    lang: 55.761581
  },
  {
    id: 829,
    name: 'Carrefour Market Raha Beach',
    lat: 24.449376,
    lang: 54.604984
  },
  {
    id: 833,
    name: 'Carrefour Market Al Seef',
    lat: 24.435711,
    lang: 54.455672
  },
  {
    id: 830,
    name: 'Carrefour Market Motor City',
    lat: 25.046193,
    lang: 55.233856
  },
  {
    id: 822,
    name: 'Carrefour Market Red Diamond',
    lat: 25.077417,
    lang: 55.155155
  },
  {
    id: 820,
    name: 'Carrefour Market Armada',
    lat: 25.074939,
    lang: 55.144662
  },
  {
    id: 831,
    name: 'Carrefour Market Dubai Investment Park',
    lat: 24.99811,
    lang: 55.175691
  },
  {
    id: 840,
    name: 'Carrefour Market The Ranches Souk',
    lat: 25.02953,
    lang: 55.270429
  },
  {
    id: 825,
    name: 'Marina Silvarene',
    lat: 25.075282,
    lang: 55.137842
  },
  {
    id: 814,
    name: 'Carrefour Market Palladium',
    lat: 25.069522,
    lang: 55.139741
  },
  { id: 835, name: 'Murjan', lat: 25.0827, lang: 55.139648 },
  {
    id: 836,
    name: 'Carrefour Market ADCP',
    lat: 24.419966,
    lang: 54.443206
  },
  {
    id: 815,
    name: 'Carrefour Market Marina Crown',
    lat: 25.09031,
    lang: 55.147928
  },
  {
    id: 812,
    name: 'Carrefour Market Tecom Vista',
    lat: 25.096798,
    lang: 55.176175
  },
  {
    id: 838,
    name: 'Carrefour Market Tecom i-rise',
    lat: 25.096667,
    lang: 55.181111
  },
  {
    id: 810,
    name: 'Carrefour Market IC France',
    lat: 25.160883,
    lang: 55.405712
  },
  {
    id: 805,
    name: 'Carrefour Market Oasis',
    lat: 25.169392,
    lang: 55.241049
  },
  {
    id: 824,
    name: 'Carrefour Market Burj Views',
    lat: 25.190641,
    lang: 55.281283
  },
  {
    id: 804,
    name: 'Carrefour Market Wafi',
    lat: 25.1608818,
    lang: 55.4057133
  },
  {
    id: 837,
    name: 'Carrefour Market Al Wasl',
    lat: 25.209478,
    lang: 55.255086
  },
  {
    id: 813,
    name: 'Carrefour Market Hudaiba',
    lat: 25.237105,
    lang: 55.27908
  },
  {
    id: 826,
    name: 'Carrefour Market Juraina',
    lat: 25.297224,
    lang: 55.490239
  },
  {
    id: 817,
    name: 'Carrefour Market Twin Tower',
    lat: 25.297738,
    lang: 55.382265
  },
  {
    id: 811,
    name: 'Carrefour Market Nahda Tower',
    lat: 25.30139,
    lang: 55.372168
  },
  {
    id: 828,
    name: 'Carrefour Market Al Khan',
    lat: 25.329036,
    lang: 55.374473
  },
  {
    id: 818,
    name: 'Carrefour Market Fardan',
    lat: 25.323696,
    lang: 55.382092
  },
  {
    id: 807,
    name: 'Carrefour Market Buhaira',
    lat: 25.329042,
    lang: 55.37448
  },
  {
    id: 821,
    name: 'Carrefour Market Sharjah Al Quoz',
    lat: 25.352533,
    lang: 55.451953
  },
  {
    id: 834,
    name: 'Carrefour Market Nasseriya',
    lat: 25.365641,
    lang: 55.403311
  },
  {
    id: 841,
    name: 'Carrefour Market Al Dana Mall',
    lat: 25.387988,
    lang: 55.4220673
  },
  {
    id: 823,
    name: 'Carrefour Market Umm Al Quwain',
    lat: 25.547722,
    lang: 55.545036
  },
  {
    id: 827,
    name: 'Carrefour Market Mirgab',
    lat: 25.387988,
    lang: 55.422067
  },
  {
    id: 'c01',
    name: 'Carrefour City Union Station',
    lat: 25.266,
    lang: 55.314
  },
  {
    id: 'c04',
    name: 'Carrefour City Burjuman Station',
    lat: 25.255,
    lang: 55.304
  },
  {
    id: 'c05',
    name: 'Carrefour City Fahidi Station',
    lat: 25.259,
    lang: 55.297
  },
  {
    id: 'c06',
    name: 'Carrefour City Sukoon',
    lat: 25.079,
    lang: 55.144
  },
  {
    id: 'c02',
    name: 'Carrefour City Barsha',
    lat: 25.111,
    lang: 55.202
  },
  {
    id: 'c03',
    name: 'Carrefour City Ali Maktoum',
    lat: 25.168,
    lang: 55.41
  },
  {
    id: 'c07',
    name: 'Carrefour City Sports City',
    lat: 25.039,
    lang: 55.219
  },
  { id: 99, name: 'DC', lat: 25.04, lang: 55.198 },
  {
    id: 18,
    name: 'Carrefour Fujairah Century Mall',
    lat: 25.157254,
    lang: 56.34893
  },
  {
    id: 72,
    name: 'Carrefour Dubai Festival City',
    lat: 25.1181005,
    lang: 55.2005963
  },
  {
    id: 73,
    name: 'Carrefour Ibn Batuta Mall',
    lat: 25.041827,
    lang: 55.118331
  },
  {
    id: 63,
    name: 'Carrefour Al Ghurair',
    lat: 25.267674,
    lang: 55.317396
  },
  {
    id: 74,
    name: 'Carrefour YAS Mall',
    lat: 24.4885216,
    lang: 54.6086723
  },
  {
    id: 75,
    name: 'Carrefour Dragon Mall 2',
    lat: 25.171079,
    lang: 55.424354
  },
  {
    id: 76,
    name: 'Carrefour Wafi Mall',
    lat: 25.229886,
    lang: 55.318984
  },
  {
    id: 842,
    name: 'Carrefour Market Silicon Oasis',
    lat: 25.114278,
    lang: 55.372109
  },
  {
    id: 'c14',
    name: 'Carrefour City JLT Gate 1 Tower (Cluster Q)',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c08',
    name: 'Carrefour City Nova Tower',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c09',
    name: 'Carrefour City Al Bunyan',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c10',
    name: 'Carrefour City Binghatti Terraces',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c12',
    name: 'Carrefour City Emirates SevenTowers',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c11',
    name: 'Carrefour City Danat Tower',
    lat: 24.4767304,
    lang: 54.3661183
  },
  {
    id: 'c13',
    name: 'Carrefour City Al Falah',
    lat: 24.4767304,
    lang: 54.3661183
  },
  {
    id: 'c15',
    name: 'Carrefour City Hydra Avenue',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c16',
    name: 'Carrefour City Duja Tower',
    lat: 25.0392031,
    lang: 55.2210743
  },
  {
    id: 'c17',
    name: 'Carrefour City Burj Al Khair',
    lat: 24.4767304,
    lang: 54.3661183
  },
  {
    id: 65,
    name: 'Carrefour Al Ain Mall',
    lat: 24.2433196,
    lang: 55.726911
  },
  {
    id: 'C08',
    name: 'Carrefour City Nova Tower',
    lat: 25.111362,
    lang: 55.376748
  },
  {
    id: 'C09',
    name: 'Carrefour City Al Bunyan',
    lat: 25.082231,
    lang: 55.147594
  },
  {
    id: 'C10',
    name: 'Carrefour City Binghatti Terraces',
    lat: 25.1248067,
    lang: 55.4016855
  },
  {
    id: 'C12',
    name: 'Carrefour City Emirates SevenTowers',
    lat: 25.114278,
    lang: 55.372109
  },
  {
    id: 'C11',
    name: 'Carrefour City Danat Tower',
    lat: 24.431951,
    lang: 54.433579
  },
  {
    id: 'C13',
    name: 'Carrefour City Al Falah',
    lat: 24.476727,
    lang: 54.366118
  },
  {
    id: 843,
    name: 'Carrefour Market Dubiotech',
    lat: 25.069234,
    lang: 55.241915
  },
  {
    id: 844,
    name: 'Carrefour Market First avenue',
    lat: 25.046513,
    lang: 55.240774
  },
  {
    id: 845,
    name: 'Carrefour Market Arabian Ranches',
    lat: 25.05699,
    lang: 55.270415
  },
  {
    id: 846,
    name: 'Carrefour Market TECOM',
    lat: 25.094684,
    lang: 55.178736
  },
  {
    id: 848,
    name: 'Carrefour Market Khalifa City A',
    lat: 24.400547,
    lang: 54.57543
  },
  {
    id: 849,
    name: 'Carrefour Market Paragon Bay',
    lat: 24.49098,
    lang: 54.393982
  },
  {
    id: 850,
    name: 'Carrefour Market Jumeirah Park',
    lat: 25.042857,
    lang: 55.159712
  },
  {
    id: 851,
    name: 'Carrefour Market Discovery Gardens',
    lat: 25.034661,
    lang: 55.146955
  },
  {
    id: 852,
    name: 'Carrefour Market Ghoroob Mirdif',
    lat: 25.211575,
    lang: 55.416449
  },
  {
    id: 853,
    name: 'Carrefour Market Remraam Community Centre',
    lat: 25.004937,
    lang: 55.249531
  },
  {
    id: 854,
    name: 'Carrefour Market Rimal',
    lat: 25.0777605,
    lang: 55.1353393
  },
  {
    id: 855,
    name: 'Carrefour Market Al Dhait',
    lat: 25.728035,
    lang: 55.920081
  },
  {
    id: 'C14',
    name: 'Carrefour City JLT Gate 1 Tower (Cluster Q)',
    lat: 25.076523,
    lang: 55.146345
  },
  {
    id: 'C01',
    name: 'Carrefour City Union Stn.',
    lat: 25.266159,
    lang: 55.313776
  },
  {
    id: 'C02',
    name: 'Carrefour City Arenco Barsha',
    lat: 25.111297,
    lang: 55.20172
  },
  {
    id: 'C03',
    name: 'Carrefour City Maktoum',
    lat: 25.168137,
    lang: 55.409705
  },
  {
    id: 'C04',
    name: 'Carrefour City Burjuman Station',
    lat: 25.254747,
    lang: 55.303701
  },
  {
    id: 'C05',
    name: 'Carrefour City Fahidi Station',
    lat: 25.258589,
    lang: 55.297451
  },
  {
    id: 'C06',
    name: 'Carrefour City Sukoon Tower',
    lat: 25.079089,
    lang: 55.143807
  },
  {
    id: 'C07',
    name: 'Carrefour City Sports City',
    lat: 25.039204,
    lang: 55.221077
  },
  {
    id: 'C15',
    name: 'Carrefour City Hydra Avenue',
    lat: 24.499376,
    lang: 54.405121
  },
  {
    id: 'C16',
    name: 'Carrefour City Duja Tower',
    lat: 25.228833,
    lang: 55.286287
  },
  {
    id: 'C17',
    name: 'Carrefour City Burj Al Khair',
    lat: 24.467994,
    lang: 54.339439
  },
  {
    id: 67,
    name: 'Masdar City Center',
    lat: 24.4293872,
    lang: 54.6166628
  },
  { id: 68, name: 'AL TALLAH MALL', lat: 25.366019, lang: 55.511071 },
  { id: 109, name: 'Carrefour UAE - Marketplace' },
  { id: 66, name: 'City Land' },
  { id: 864, name: 'Carrefour Market Al Musalla' },
  { id: 'D01', name: 'Wafi Mall' },
  {
    id: 'CNC_837',
    name: 'Al Wasl Vita Mall, Carrefour market',
    lat: 25.209478,
    lang: 55.255086
  },
  {
    id: 'CNC_076',
    name: 'Wafi Mall, Carrefour Customer service',
    lat: 25.2295,
    lang: 55.3186
  },
  {
    id: 'CNC_003',
    name: 'City Centre Deira, Carrefour Customer service',
    lat: 25.252,
    lang: 55.3328
  },
  {
    id: 'CNC_015',
    name: 'City Centre Mirdif, Carrefour Customer service',
    lat: 25.1304,
    lang: 55.2433
  },
  {
    id: 'CNC_003_1',
    name: 'City Centre Deira, Parking P2 L20 & K20',
    lat: 25.252,
    lang: 55.3328
  },
  {
    id: 'CNC_015_1',
    name: 'City Centre Mirdif, Parking P3 G57 & G58',
    lat: 25.1304,
    lang: 55.2433
  },
  {
    id: 'D04',
    name: 'Al Saqr, Carrefour HyperMarket, Customer Service Desk',
    lat: 25.209478,
    lang: 55.255086
  },
  {
    id: 69,
    name: 'Carrefour Al Murad Mall',
    lat: 25.389104,
    lang: 55.439288
  },
  {
    id: 'CNC_011',
    name: 'Mall Of the Emirates, Carrefour Customer service Desk',
    lat: 25.1181,
    lang: 55.2006
  },
  {
    id: 'CNC_064_1',
    name: 'City Center Me’aisem, Parking',
    lat: 25.0403,
    lang: 55.1968
  },
  {
    id: 'CNC_011_1',
    name: 'Mall Of the Emirates, Parking C3',
    lat: 25.1181,
    lang: 55.2006
  },
  {
    id: 'CNC_064',
    name: 'City Center Me’aisem, Carrefour Customer Service Desk',
    lat: 25.0403,
    lang: 55.1968
  },
  {
    id: 'CNC_067',
    name: 'My City Centre Masdar, Carrefour Customer Service'
  },
  {
    id: 'CNC_072',
    name: 'Dubai Festival City, Carrefour Customer service',
    lat: 25.222437,
    lang: 55.35641
  },
  {
    id: 'CNC_066',
    name: 'City Land Mall, Carrefour Customer service',
    lat: 25,
    lang: 55
  },
  {
    id: 'CNC_009',
    name: 'City Center Shindagha, Carrefour Customer Service',
    lat: 25.2645,
    lang: 55.2874
  },
  {
    id: 'CNC_075',
    name: 'Dragon Mart, Carrefour Customer Service',
    lat: 25,
    lang: 55
  },
  {
    id: 'D05',
    name: 'Al Garhoud Tent',
    lat: 25.209478,
    lang: 55.255086
  },
  {
    id: 'CNC_072_CLEVERON',
    name: 'DIFC Gate Avenue Parking - Self Service Pickup',
    lat: 25.210124,
    lang: 55.277667
  },
  { id: 857, name: 'Springs Souq', lat: 25.209478, lang: 55.255086 },
  {
    id: 'CNC_D05',
    name: 'Carrefour Garhoud Tent',
    lat: 25.1446,
    lang: 55.2037
  },
  { id: 'D02', name: 'IBN Battuta Mall' },
  { id: 70, name: 'Al Zahia City Center - Sharjah' },
  { id: 'D03', name: 'Cityland Mall' },
  {
    id: 'CNC_004',
    name: 'Al Saqr Airport Road, Carrefour Customer Service',
    lat: 24.4239102873263,
    lang: 54.4526831608479
  },
  {
    id: 'CNC_061',
    name: 'Deerfields Mall, Carrefour Customer Service',
    lat: 24.5723182286899,
    lang: 54.6499425697168
  },
  {
    id: 'CNC_074',
    name: 'Yas Mall, Carrefour Customer Service',
    lat: 24.48902012,
    lang: 54.6088475
  },
  {
    id: 'CNC_006',
    name: 'Marina Mall, Carrefour Customer Service Desk',
    lat: 24.4755371904517,
    lang: 54.3220870833638
  },
  {
    id: 'CNC_007',
    name: 'Al Jimi Mall, Carrefour Customer Service Desk',
    lat: 24.2537460020367,
    lang: 55.7371950990987
  },
  { id: 860, lat: 25.08649, lang: 55.146672 },
  { id: 'C28', lat: 25.072055, lang: 55.131492 },
  { id: 'C27', lat: 25.083628, lang: 55.144603 }
];
