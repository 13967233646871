import React from 'react';
import '../../styles/productDetails.css';
export const TextSplitter = ({ text, delimiter = '\n' }) => {
  return Boolean(text) ? (
    text
      .split(delimiter)
      .map((textChunk, index) => (
        <p key={index} className="description" dangerouslySetInnerHTML={{ __html: textChunk }}></p>
      ))
  ) : (
    <></>
  );
};
