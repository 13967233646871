import { PRODUCT_TYPES } from '../constants';

const useProduct = () => {
  const getProductNameForApi = (productTypeVal, pid) => {
    switch (productTypeVal) {
      case PRODUCT_TYPES.EFPOMANEGGS:
        return PRODUCT_TYPES.EGGS;
      case PRODUCT_TYPES.IFFCOEGGS:
        return PRODUCT_TYPES.EGGS;
      case PRODUCT_TYPES.GIPF_OMAN_EGGS:
        return PRODUCT_TYPES.EGGS;
      case PRODUCT_TYPES.INDIAGRAPES:
      case PRODUCT_TYPES.KARSTENGRAPES:
      case PRODUCT_TYPES.MEDI_GARDEN_GRAPES:
      case PRODUCT_TYPES.EGYPT_GRAPES:
      case PRODUCT_TYPES.INDIA_MULHOUSE_GRAPES:
        return PRODUCT_TYPES.GRAPES;
      case PRODUCT_TYPES.RTEAVOCADO:
        return 'avocado';
      case PRODUCT_TYPES.ELITEPOTATO:
      case PRODUCT_TYPES.ELITEPOTATO_PROMO:
        return 'potato';
      case PRODUCT_TYPES.PURE_HARVEST_TOMATO:
        return 'tomato';
      case PRODUCT_TYPES.SALMON:
        return 'salmon';
      case PRODUCT_TYPES.SEABREAM_FISH:
        return 'fish';
      case PRODUCT_TYPES.AGRICO_MUSHROOM:
        return 'mushroom';
      case PRODUCT_TYPES.HAYATNA_MILK:
        if (!['6290361531525', '6290361531532'].includes(pid)) {
          return 'fresh-milk';
        } else {
          return 'milk';
        }
      default:
        return productTypeVal || 'chicken';
    }
  };

  return {
    getProductNameForApi
  };
};

export default useProduct;
