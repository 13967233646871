import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FREQUENT_WORDS, LANGUAGES, PRODUCT_TYPES } from '../../constants';

const InputLabel = () => {
  const { productTypeSlug, lang, productCode } = useParams();
  const label = useMemo(() => {
    let dispalyText = '';
    const frequentWordsOfSpecificLanguage =
      lang === LANGUAGES.ARABIC.langCode ? FREQUENT_WORDS.ARABIC : FREQUENT_WORDS.ENGLISH;
    switch (productTypeSlug) {
      case PRODUCT_TYPES.HONEY:
        dispalyText = frequentWordsOfSpecificLanguage.COUNTRY_PRODUCTION_LOT;
        break;
      case PRODUCT_TYPES.GRAPES:
      case PRODUCT_TYPES.COFFEE:
      case PRODUCT_TYPES.ORGANICCARROTS:
      case PRODUCT_TYPES.ORGANICCUCUMBER:
      case PRODUCT_TYPES.INDIAGRAPES:
      case PRODUCT_TYPES.SALADLEAVES:
      case PRODUCT_TYPES.ELITEPOTATO:
      case PRODUCT_TYPES.PURE_HARVEST_TOMATO:
      case PRODUCT_TYPES.MUSHROOM:
      case PRODUCT_TYPES.BLUEBERRY:
      case PRODUCT_TYPES.EGYPT_GRAPES:
      case PRODUCT_TYPES.MEDI_GARDEN_GRAPES:
      case PRODUCT_TYPES.STRAWBERRY:
      case PRODUCT_TYPES.INDIA_MULHOUSE_GRAPES:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_LOT;
        break;
      case PRODUCT_TYPES.EGGS:
      case PRODUCT_TYPES.MILK:
      case PRODUCT_TYPES.EFPOMANEGGS:
      case PRODUCT_TYPES.GIPF_OMAN_EGGS:
      case PRODUCT_TYPES.IFFCOEGGS:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_LOT_DATE;
        break;
      case PRODUCT_TYPES.ORGANICCHICKEN:
        dispalyText = frequentWordsOfSpecificLanguage.CHICKEN_TYPE_AND_PRODUCTION_DATE;
        break;
      case PRODUCT_TYPES.HYDROPONICS:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_STORE_AND_DATE;
        break;
      case PRODUCT_TYPES.FRESHMILK:
      case PRODUCT_TYPES.HAYATNA_MILK:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_DATE_TITLE;
        break;
      case PRODUCT_TYPES.KARSTENGRAPES:
        if (productCode === '6294017503317') {
          dispalyText = frequentWordsOfSpecificLanguage.LOT_NO_GRAPES_TYPES;
        } else {
          dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_LOT;
        }
        break;
      case PRODUCT_TYPES.FISH:
      case PRODUCT_TYPES.LABAN:
      case PRODUCT_TYPES.SALMON:
      case PRODUCT_TYPES.SEABREAM_FISH:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_DATE;
        break;
      case PRODUCT_TYPES.RTEAVOCADO:
        dispalyText = frequentWordsOfSpecificLanguage.ENTER_PACKING_CODE;
        break;
      case PRODUCT_TYPES.AGRICO_MUSHROOM:
        dispalyText = frequentWordsOfSpecificLanguage.SELECT_PACKING_DATE;
        break;
      default:
        dispalyText = frequentWordsOfSpecificLanguage.PRODUCTION_CODE;
        break;
    }
    return dispalyText;
  }, [lang, productCode, productTypeSlug]);

  return (
    <p data-testid="input-label" className="barcodeHeader">
      {label}
    </p>
  );
};

export default InputLabel;
