/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved, import/extensions, import/no-extraneous-dependencies */
import React, { Component } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import './demo.css';

class SocialShare extends Component {
  render() {
    const shareUrl = `https://traceability.carrefouruae.com/product/${this.props.lang}/${this.props.productType}/${this.props.productCode}/${this.props.storecode}/${this.props.date}`;
    // /product/:lang/:productType/:productcode/:storecode/:date
    const title = 'Carrefour';
    return (
      <div className="Demo__container">
        <div className="Demo__some-network">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="icon_name_holder Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
            <span>Facebook</span>
          </FacebookShareButton>
        </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="icon_name_holder Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
            <span>Twitter</span>
          </TwitterShareButton>
        </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="icon_name_holder Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
            <span>Whatsapp</span>
          </WhatsappShareButton>
        </div>

        <div className="Demo__some-network">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="icon_name_holder Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
            <span>Email</span>
          </EmailShareButton>
        </div>
      </div>
    );
  }
}

export default SocialShare;
