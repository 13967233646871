import React, { useMemo } from 'react';
import { fromAndToDateFormatter } from '../../../utils';
import { LANGUAGES, JOURNEYS, PRODUCT_TYPES } from '../../../constants';
const ShowItemDates = ({ item, language, productType }) => {
  let fromdate = new Date(item.dateFrom),
    todate = new Date(item.dateTo);
  if (fromdate.getTime() > todate.getTime()) {
    const tempDate = fromdate;
    fromdate = todate;
    todate = tempDate;
  }
  const format = item.dateFormat;

  const formattedDate = useMemo(() => {
    let date = '';
    if (item.journey === JOURNEYS.GERMINATION) {
      date = item.dateFrom;
    } else {
      date = fromAndToDateFormatter(
        fromdate,
        todate,
        language === LANGUAGES.ARABIC.langCode ? LANGUAGES.ARABIC.langConvertCode : null,
        format
      );
    }
    return [
      PRODUCT_TYPES.EGGS,
      PRODUCT_TYPES.EFPOMANEGGS,
      PRODUCT_TYPES.IFFCOEGGS,
      PRODUCT_TYPES.GIPF_OMAN_EGGS
    ].includes(productType)
      ? `${item.subtitle} : ${date}`
      : date;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.subtitle]);

  return (
    <div className="dateBoxFull" style={{ width: formattedDate.length < 26 ? '250px' : 'auto' }}>
      <span className={`${language === LANGUAGES.ARABIC.langCode ? 'dateTextAr' : 'dateText'}`}>
        {formattedDate}
      </span>
    </div>
  );
};

export default ShowItemDates;
