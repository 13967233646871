import React, { memo } from 'react';
import Image from '../common/Image';
import { TextSplitter } from '../common/TextSplitter';
import { convertYYYYtoDD } from '../../utils';
import { useSelector } from 'react-redux';

const ProductTitleAndDesc = () => {
  const { apiData } = useSelector(({ productReducers }) => productReducers);
  const { title, productMainUrl, productDescription, tableData } = apiData;

  return (
    <div>
      <div>
        <h3 className="productHeader">{title}</h3>
        <div></div>
        {productMainUrl && (
          <div className="mainImageContainer">
            {' '}
            <Image className="mainImage" src={productMainUrl} alt="product" loading="lazy" />
            {/* <img className="mainImage" src={chicken} alt="product"  loading="lazy"/> */}
          </div>
        )}
        {Boolean(tableData) && (
          <table className="table">
            <thead></thead>
            <tbody>
              {Boolean(tableData) &&
                tableData.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td className="thtd tdkey">{item.key}</td>
                      <td className="thtd tdvalue">
                        <span className="test">
                          {Boolean(Date.parse(item.val)) ? convertYYYYtoDD(item.val) : item.val}
                        </span>{' '}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        <TextSplitter text={productDescription} />
      </div>
    </div>
  );
};

export default memo(ProductTitleAndDesc);
