import React, { memo, useState } from 'react';
import SocialShare from '../product-details/socialshare';
import { FREQUENT_WORDS, LANGUAGES, PRODUCT_TYPES } from '../../constants';
import { convertDateIntoDahedFormat } from '../../utils/index';

const SocialShareCommonComponent = ({
  UAEFLAG,
  language,
  handleLanguageChange,
  shareImg,
  productCode,
  date,
  lotno,
  showShare,
  downarrow,
  storecode,
  productType
}) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const dir = language === 'ar' ? 'left' : 'right';
  const shareDetails = (e) => {
    e.preventDefault();
    setShowShareMenu(!showShareMenu);
  };
  const languages =
    PRODUCT_TYPES.HYDROPONICS === productType ? { ENGLISH: LANGUAGES.ENGLISH } : LANGUAGES;
  return (
    <div className="shareContainer">
      <div>
        <img src={UAEFLAG} alt="uaeflag" className="selectImage"></img>
        <select
          value={language}
          style={{ background: `url(${downarrow}) no-repeat ${dir} #fff` }}
          onChange={handleLanguageChange}
          className="selectDropdown"
        >
          {Object.keys(languages).map((lang, index) => {
            return (
              <option value={LANGUAGES[lang].langCode} key={index}>
                {LANGUAGES[lang].language}
              </option>
            );
          })}
        </select>
      </div>

      {showShare && (
        <div>
          <button onClick={shareDetails} className="btnStyleSocial ">
            {' '}
            <img src={shareImg} alt="share" className="selectImage"></img>
            {language === LANGUAGES.ARABIC.langCode
              ? FREQUENT_WORDS.ARABIC.SHARE
              : FREQUENT_WORDS.ENGLISH.SHARE}
          </button>
        </div>
      )}
      {showShareMenu &&
        Boolean(productCode) &&
        Boolean(date) &&
        Boolean(productType) &&
        Boolean(storecode) && (
          <div className="social">
            <SocialShare
              lang={language === LANGUAGES.ARABIC.langCode ? language : LANGUAGES.ENGLISH.langCode}
              productCode={productCode}
              lotno={lotno}
              date={convertDateIntoDahedFormat(date)}
              storecode={storecode}
              productType={productType}
            />
          </div>
        )}
    </div>
  );
};

export default memo(SocialShareCommonComponent);
